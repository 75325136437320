import React from 'react';
import { Form } from 'semantic-ui-react';

export default function TextAnswerField(props) {
  return (
    <Form.Input
      fluid
      label="Answer"
      value={props.answer}
      placeholder="Provide the answer here..."
      onChange={props.changeAnswer}
      required
    />
  );
}
