import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Header, Icon, Modal, Button, Divider } from 'semantic-ui-react';

class RemoveRoundModal extends Component {
  state = { open: false };

  open = () => this.setState({ open: true });
  close = (e, data) => {
    this.setState({ open: false });
    if (data.action && data.action === 'remove' && data.quiz && data.round) {
      this.props.stores.quiz.removeRoundFromQuiz(data.round, data.quiz);
    }
    if (data.action && data.action === 'delete' && data.round) {
      alert('Delete round ' + data.round.id);
    }
  };

  render() {
    const { open } = this.state;
    const { quiz, round, roundNumber } = this.props;

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button basic icon="trash alternate outline" />
          )
        }
        open={open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>Remove round {roundNumber}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Round {roundNumber} will removed from this quiz but can be added
              again later. All the questions will still remain in the round.
            </p>
            <p>
              <Button
                color="orange"
                onClick={this.close}
                action="remove"
                quiz={quiz}
                round={round}
              >
                <Icon name="trash alternate outline" /> Remove
              </Button>
            </p>
          </Modal.Description>
          <Divider />
          <Modal.Description>
            <Header>Delete the round?</Header>
            <p>
              <Icon name="warning sign" color="red" />
              Warning: The round will be deleted and cannot be recovered. The
              questions will not be deleted and can still be used in another
              round.
            </p>
            <p>
              <Button color="red" onClick={this.close} action="delete" disabled>
                <Icon name="delete" /> Delete
              </Button>
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(RemoveRoundModal);
