import { observable, action } from 'mobx';
import firebase from 'firebase';

var quizStore = observable({
  loading: true,
  quizDB: firebase.firestore().collection('quizzes'),
  quizRefs: new Map(),
  quizzes: observable(new Map()),

  subscribe(quizID) {
    if (!this.quizRefs.has(quizID)) {
      console.log('Subscribing to quiz ' + quizID);
      this.quizRefs.set(
        quizID,
        this.quizDB.doc(quizID).onSnapshot(
          function(doc) {
            this.loading = false;
            const id = doc.ref.id;
            const data = doc.data();
            // console.log(id);
            // console.log(data);
            if (data) {
              // console.log('Updating data for quiz: ' + id);
              if (data.rounds) {
                var rounds = new Map();
                Object.getOwnPropertyNames(data.rounds).forEach(rid => {
                  rounds.set(rid, data.rounds[rid]);
                  this.subscribeToRound(data.rounds[rid]);
                });
                data.rounds = rounds;
              }
              data.id = id;
              this.quizzes.set(id, data);
            } else {
              console.log('Quiz ' + id + ' removed');
              this.quizzes.delete(id);
            }
          }.bind(this)
        )
      );
    }
  },

  unsubscribe() {
    this.loading = true;
    this.quizzes.clear();
    this.quizRefs.clear();
    if (this.closeDBSession) {
      this.closeDBSession();
    }
  },

  unsubscribeToQuiz(quizID) {
    // console.log('Unsubscribing to quiz ' + quizID);
    const ref = this.quizRefs.get(quizID);
    if (ref) ref();
    this.quizRefs.delete(quizID);
    this.quizzes.delete(quizID);
  },

  quiz(quizID) {
    const quiz = this.quizzes.get(quizID);
    return quiz;
  },

  quizRounds(quizID) {
    const quiz = this.quiz(quizID);
    if (!quiz) return null;

    return Array.from(quiz.rounds.values())
      .map(v => this.rounds.get(v))
      .filter(r => r !== undefined && r !== null); //XXX do we want to do this?
  },

  quizQuestions(quizID) {
    const rounds = this.quizRounds(quizID);
    if (!rounds) return null;
    let results = [];
    rounds.forEach(r => {
      if (r) {
        results.push(
          ...Array.from(r.questions.values())
            .map(q => this.questions.get(q))
            .filter(q => q !== undefined && q !== null) //XXX do we want to do this?
        );
      }
    });
    return results;
  },

  /* Rounds */
  rounds: observable(new Map()),
  roundRefs: new Map(),

  roundsDB: firebase.firestore().collection('rounds'),

  subscribeToRound(roundID) {
    if (!this.roundRefs.has(roundID)) {
      // console.log('Subscribing to round ' + roundID);
      this.roundRefs.set(
        roundID,
        this.roundsDB.doc(roundID).onSnapshot(
          function(doc) {
            const id = doc.ref.id;
            const data = doc.data();
            // console.log(id);
            // console.log(data);
            if (data) {
              // console.log('Updating data for round: ' + id);
              var questions = new Map();
              if (data.questions) {
                Object.getOwnPropertyNames(data.questions).forEach(qid => {
                  questions.set(qid, data.questions[qid]);
                  this.subscribeToQuestion(data.questions[qid]);
                });
              }
              data.questions = questions;
              data.id = id;
              this.rounds.set(id, data);
            } else {
              // console.log('deleting round ' + id);
              this.rounds.delete(id);
            }
          }.bind(this)
        )
      );
    }
  },

  unsubscribeToRound(roundID) {
    // console.log('Unsubscribing to round ' + roundID);
    const ref = this.roundRefs.get(roundID);
    if (ref) {
      ref();
    }
    this.roundRefs.delete(roundID);
    this.rounds.delete(roundID);

    //XXX should unsubscribe to questions
  },

  createRound: action(function(title, quiz) {
    const roundRef = this.roundsDB.doc();

    roundRef
      .set({
        title: title || null,
        questions: {}
      })
      .then(function() {
        console.log('Document successfully written!');
      })
      .catch(function(error) {
        console.error('Error writing document: ', error);
      });

    if (quiz) {
      var quizRef = this.quizDB.doc(quiz.id);
      if (quizRef) {
        const roundNumber =
          quiz.rounds.size > 0 ? Math.max(...quiz.rounds.keys()) + 1 : 1;
        var roundsUpdate = {};
        roundsUpdate[`rounds.${roundNumber}`] = roundRef.id;
        quizRef.update(roundsUpdate);
      }
    }
  }),

  removeRoundFromQuiz: action(function(round, quiz) {
    if (quiz) {
      // if quiz contains the specified round
      if (Array.from(quiz.rounds.entries()).find(e => e[1] === round.id)) {
        const rounds = Array.from(quiz.rounds.entries())
          .filter(r => r[1] !== round.id)
          .sort((a, b) => a[0] - b[0]);

        var updatedRounds = {};
        for (var i = 0; i < rounds.length; i++) {
          updatedRounds[`${i + 1}`] = rounds[i][1];
        }
        var quizRef = this.quizDB.doc(quiz.id);
        quizRef.update({ rounds: updatedRounds });

        this.unsubscribeToRound(round.id);
      }
    }
  }),

  /* Questions */
  questions: observable(new Map()),
  questionRefs: new Map(),

  questionsDB: firebase.firestore().collection('questions'),

  subscribeToQuestion(questionID) {
    if (!this.questionRefs.has(questionID)) {
      // console.log('Subscribing to question ' + questionID);
      this.questionRefs.set(
        questionID,
        this.questionsDB.doc(questionID).onSnapshot(
          function(doc) {
            const id = doc.ref.id;
            const data = doc.data();
            // console.log(id);
            // console.log(data);
            if (data) {
              // console.log('Updating data for question: ' + id);
              if (data.type === 'multiple' && data.options) {
                var options = new Map();
                Object.getOwnPropertyNames(data.options).forEach(id => {
                  options.set(parseInt(id), data.options[id]);
                });
                data.options = options;
              }
              data.id = id;
              this.questions.set(id, data);
            } else {
              // console.log('deleting question ' + id);
              this.questions.delete(id);
            }
          }.bind(this)
        )
      );
    }
  },

  unsubscribeToQuestion(questionID) {
    // console.log('Unsubscribing to question ' + questionID);
    const ref = this.questionRefs.get(questionID);
    if (ref) {
      ref();
    }
    this.questionRefs.delete(questionID);
    this.questions.delete(questionID);
  },

  getQuestions(cb) {
    console.log('Retrieving all questions');
    this.questionsDB.get().then(function(querySnapshot) {
      let results = new Map();
      querySnapshot.forEach(function(doc) {
        const id = doc.ref.id;
        const data = doc.data();

        if (data.type === 'multiple' && data.options) {
          var options = new Map();
          Object.getOwnPropertyNames(data.options).forEach(id => {
            options.set(parseInt(id), data.options[id]);
          });
          data.options = options;
        }
        data.id = id;
        results.set(id, data);
      });
      cb(results);
    });
  },

  createQuestion: action(function(question, round) {
    //XXX need more checks to validate the question
    if (question) {
      const questionRef = this.questionsDB.doc();

      question['createdAt'] = firebase.firestore.FieldValue.serverTimestamp();
      questionRef
        .set(question)
        .then(function() {
          console.log('Document successfully written!');
        })
        .catch(function(error) {
          console.error('Error writing document: ', error);
        });

      if (round) {
        const questionNumber =
          round.questions.size > 0
            ? Math.max(...round.questions.keys()) + 1
            : 1;
        var questionsUpdate = {};
        questionsUpdate[`questions.${questionNumber}`] = questionRef.id;
        var roundRef = this.roundsDB.doc(round.id);
        roundRef.update(questionsUpdate);
      }
    }
  }),

  updateQuestion: action(function(id, question) {
    var questionRef = this.questionsDB.doc(id);

    //XXX need more checks to validate the question
    if (questionRef) {
      question['updatedAt'] = firebase.firestore.FieldValue.serverTimestamp();
      questionRef
        .set(question, { merge: true })
        .then(function() {
          console.log('Document successfully written!');
        })
        .catch(function(error) {
          console.error('Error writing document: ', error);
        });
    }
  }),

  roundContainsQuestion(round, question) {
    if (round && question) {
      return Array.from(round.questions.entries()).find(
        e => e[1] === question.id
      );
    }
    return false;
  },

  roundContainsQuestionID(round, id) {
    return round && id && Array.from(round.questions.values()).includes(id);
  },

  addQuestionsToRound: action(function(ids, round) {
    if (round) {
      let questionNumber =
        round.questions.size > 0 ? Math.max(...round.questions.keys()) : 0;

      if (ids instanceof String) ids = [ids];

      var updatedQuestions = {};
      round.questions.forEach((id, number) => (updatedQuestions[number] = id));

      ids.forEach(id => {
        if (!this.roundContainsQuestionID(round, id)) {
          updatedQuestions[`${++questionNumber}`] = id;
          this.subscribeToQuestion(id);
        }
      });
      var roundRef = this.roundsDB.doc(round.id);
      roundRef.update({ questions: updatedQuestions });
    }
  }),

  removeQuestionFromRound: action(function(question, round) {
    if (this.roundContainsQuestion(round, question)) {
      const questions = Array.from(round.questions.entries())
        .filter(r => r[1] !== question.id)
        .sort((a, b) => a[0] - b[0]);

      var updatedQuestions = {};
      for (var i = 0; i < questions.length; i++) {
        updatedQuestions[`${i + 1}`] = questions[i][1];
      }
      var roundRef = this.roundsDB.doc(round.id);
      roundRef.update({ questions: updatedQuestions });

      this.unsubscribeToQuestion(question.id);
    }
  }),

  moveQuestionInRound: action(function(question, round, location) {
    if (this.roundContainsQuestion(round, question)) {
      let questions = Array.from(round.questions.entries())
        .sort((a, b) => a[0] - b[0])
        .map(e => e[1]);

      const oldIndex = questions.indexOf(question.id);
      const newIndex = location - 1;

      if (oldIndex === newIndex) return;
      if (oldIndex < 0) return;
      if (newIndex < 0 || newIndex > questions.length - 1) return;

      // console.log(oldIndex + ' --> ' + newIndex);

      const [removed] = questions.splice(oldIndex, 1);
      questions.splice(newIndex, 0, removed);

      var updatedQuestions = {};
      for (var i = 0; i < questions.length; i++) {
        updatedQuestions[`${i + 1}`] = questions[i];
      }
      var roundRef = this.roundsDB.doc(round.id);
      roundRef.update({ questions: updatedQuestions });
    }
  })
});

export default quizStore;
