import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Form } from 'semantic-ui-react';

class MultipleChoiceAnswerField extends Component {
  render() {
    const { options } = this.props;

    let newIndex = 1;

    let fields = [];
    if (options && options.size > 0) {
      newIndex = Math.max(...Array.from(options.keys())) + 1;
      for (let o of Array.from(options.entries()).sort((a, b) => a[0] - b[0])) {
        const index = parseInt(o[0]);
        const option = o[1];

        fields.push(
          <Form.Group key={index} unstackable>
            <Form.Radio
              value={index}
              onChange={this.props.changeAnswer}
              checked={this.props.answer === index}
              style={{ marginTop: '0.7em' }}
              width={2}
              toggle
            />
            <Form.Input
              value={option}
              index={index}
              onChange={this.props.changeOption}
              error={this.props.answer !== index}
              width={12}
            />
            <Form.Button
              icon="minus"
              width={1}
              index={index}
              value={null}
              onClick={this.props.changeOption}
            />
          </Form.Group>
        );
      }
    }

    return (
      <div>
        {fields}
        <Form.Button
          icon="add"
          value={''}
          index={newIndex}
          onClick={this.props.changeOption}
        />
      </div>
    );
  }
}

export default observer(MultipleChoiceAnswerField);
