import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Header,
  Label,
  Table,
  Dropdown,
  Confirm,
  Segment,
  Icon,
  Responsive
} from 'semantic-ui-react';

import RegisterForm from '../../components/team/register.form';

class AdminScreen extends Component {
  state = { confirmingDeletion: false, teamToDelete: null };

  openDeletionConfirmation = (e, data) => {
    if (data.team) {
      this.setState({ confirmingDeletion: true, teamToDelete: data.team });
    }
  };
  deletionConfirmed = (e, value) => {
    const team = this.state.teamToDelete;
    this.setState({ confirmingDeletion: false, teamToDelete: null });
    this.props.stores.teams.deleteTeam(team.id);
  };
  closeDeletionConfirmation = () =>
    this.setState({ confirmingDeletion: false, teamToDelete: null });

  toggleVisibility = (e, data) => {
    if (this.state.passcodeVisible === data.team) {
      this.setState({ passcodeVisible: null });
    } else {
      this.setState({ passcodeVisible: data.team });
    }
  };

  render() {
    const teams = this.props.stores.teams.teams;

    //XXX this should link to team details screen to:
    //    - find team passcode
    //    - identify and remove inactive users
    //    - show answers and verifications
    //    - shows scores
    return (
      <div>
        <Header icon textAlign="center">
          <Header.Content>Teams</Header.Content>
        </Header>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Team</Table.HeaderCell>
              <Table.HeaderCell>Passcode</Table.HeaderCell>
              <Responsive as={Table.HeaderCell} minWidth={768} />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {teams &&
              teams.map(t => (
                <Table.Row key={t.id}>
                  <Table.Cell>{t.name}</Table.Cell>
                  <Table.Cell>
                    <Label
                      as="a"
                      color={this.state.passcodeVisible === t.id ? 'red' : null}
                      size="large"
                      onClick={this.toggleVisibility}
                      team={t.id}
                    >
                      {this.state.passcodeVisible === t.id && (
                        <span>{t.passcode}</span>
                      )}
                      {this.state.passcodeVisible !== t.id && (
                        <span>&bull; &bull; &bull; &bull;</span>
                      )}
                    </Label>
                  </Table.Cell>
                  <Responsive as={Table.Cell} minWidth={768} textAlign="center">
                    <Icon name="users" color="grey" /> {t.users.length}
                  </Responsive>
                  <Table.Cell textAlign="right">
                    <Dropdown icon="ellipsis horizontal">
                      <Dropdown.Menu direction="left">
                        <Dropdown.Item
                          text="Remove"
                          onClick={this.openDeletionConfirmation}
                          team={t}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <Confirm
          header={
            "Remove '" +
            (this.state.teamToDelete ? this.state.teamToDelete.name : '') +
            "'"
          }
          content={
            "Are you sure you want to remove team '" +
            (this.state.teamToDelete ? this.state.teamToDelete.name : '') +
            "'? All team data will be lost, including existing answers. This cannot be undone!"
          }
          cancelButton="Never mind"
          confirmButton="Delete"
          open={this.state.confirmingDeletion}
          onCancel={this.closeDeletionConfirmation}
          onConfirm={this.deletionConfirmed}
        />
        <Segment>
          <Header textAlign="center">Create a team</Header>
          <RegisterForm teams={teams} showPasscode={true} />
        </Segment>
      </div>
    );
  }
}

export default inject('stores')(observer(AdminScreen));
