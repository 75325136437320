import React from 'react';
import { Form } from 'semantic-ui-react';

export default function ReferenceInput(props) {
  return (
    <Form.Input
      fluid
      value={props.reference}
      placeholder="Provide the URL or citation here..."
      onChange={props.changeReference}
    />
  );
}
