import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import {
  Dimmer,
  Loader,
  Container,
  Header,
  Segment,
  Divider
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import JoinForm from '../../components/team/join.form';
// import RegisterForm from '../../components/team/register.form';

class JoinScreen extends Component {
  render() {
    const loading = this.props.stores.teams.loading;
    const teams = this.props.stores.teams.teamNames;
    // const user = this.props.stores.teams.user;

    return (
      <Container text>
        {loading && (
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        )}
        {!loading && (
          <div>
            <Segment>
              <Header textAlign="center">Join a team</Header>
              <JoinForm teams={teams} />
            </Segment>
            <Divider horizontal>OR</Divider>
            {/* <Segment>
              <Header textAlign="center">Create a team</Header>
              <RegisterForm teams={teams} user={user} />
            </Segment>
            <Divider horizontal>OR</Divider> */}
            <Segment vertical textAlign="center">
              <Link to="/login">Login as Quiz Master</Link>
            </Segment>
          </div>
        )}
      </Container>
    );
  }
}

export default inject('stores')(observer(JoinScreen));
