import React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Statistic,
  Header,
  Segment,
  Divider,
  Loader,
  Table,
  Label
} from 'semantic-ui-react';

import Advert from '../components/sponsor/advert.panel';
import QuizStartMessage from '../components/start.message';

class ScorecardScreen extends Component {
  render() {
    const event = this.props.stores.events.activeEvent;
    const quiz = this.props.stores.events.activeQuiz;
    const team = this.props.stores.teams.activeTeam;
    const scores = this.props.stores.events.teamScores(team);
    const hasSponsors =
      event !== undefined &&
      event.sponsors !== undefined &&
      event.sponsors.length > 0;

    const roundsCompleted = event.progress.round;
    const roundsReleased = event.progress.released;
    const score = scores.rounds.get(roundsReleased);

    if (roundsReleased === null || roundsReleased < 0) {
      return (
        <div>
          <QuizStartMessage />
          <Segment textAlign="center">
            <Header textAlign="center">Scorecard</Header>
            <p>No scores yet</p>
          </Segment>
          <Divider />
          <Advert message="Thanks to our sponsors" />
        </div>
      );
    }

    if (!score) {
      return (
        <div>
          <QuizStartMessage />
          <Segment textAlign="center">
            <Header textAlign="center">Scorecard</Header>

            <Loader active inline="centered">
              Waiting for scores
            </Loader>
          </Segment>
          <Divider />
          <Advert message="Thanks to our sponsors" />
        </div>
      );
    }

    const rows = [];
    for (var i = 0; i <= roundsCompleted; i++) {
      if (i <= roundsReleased) {
        const s = scores.rounds.get(i);
        if (s) {
          rows.push(
            <Table.Row key={i}>
              <Table.Cell>{i}</Table.Cell>
              <Table.Cell>
                {s.score}{' '}
                <span style={{ color: 'grey', fontSize: '0.9rem' }}>
                  / {s.roundMax}
                </span>
              </Table.Cell>
              <Table.Cell>
                {s.cumulative}{' '}
                <span style={{ color: 'grey', fontSize: '0.9rem' }}>
                  / {s.cumulativeMax}
                </span>
              </Table.Cell>
            </Table.Row>
          );
        }
      } else {
        var msg = 'Pending';
        if (i === roundsCompleted && event.progress.phase === 'ready')
          msg = 'Ready';
        if (i === roundsCompleted && event.progress.phase === 'open')
          msg = 'Open';
        rows.push(
          <Table.Row key={i} disabled>
            <Table.Cell>{i}</Table.Cell>
            <Table.Cell>{msg}</Table.Cell>
            <Table.Cell />
          </Table.Row>
        );
      }
    }

    return (
      <div>
        <QuizStartMessage />
        <Header textAlign="center" style={{ marginBottom: '1.5em' }}>
          Scorecard
        </Header>
        {hasSponsors && (
          <Segment style={{ backgroundColor: '#ebf6ff' }}>
            <Label
              color="blue"
              style={{
                position: 'absolute',
                top: '-1.3em',
                left: '-1em'
              }}
            >
              Thanks to our sponsors
            </Label>
            <Advert compact />
          </Segment>
        )}
        <Segment.Group>
          <Segment>
            <Statistic.Group widths="two">
              <Statistic>
                <Statistic.Value>{roundsReleased}</Statistic.Value>
                <Statistic.Label>
                  Round
                  {roundsReleased > 1 ? 's' : ''}{' '}
                  <span style={{ color: 'grey', fontSize: '0.9rem' }}>
                    / {quiz.rounds.size}
                  </span>
                </Statistic.Label>
              </Statistic>

              <Statistic>
                <Statistic.Value>{score.cumulative} </Statistic.Value>
                <Statistic.Label>
                  Point
                  {score.cumulative !== 1 ? 's' : ''}{' '}
                  <span style={{ color: 'grey', fontSize: '0.9rem' }}>
                    / {score.cumulativeMax}
                  </span>
                </Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Segment>
        </Segment.Group>
        <Table unstackable textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Round</Table.HeaderCell>
              <Table.HeaderCell>Score</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
        </Table>
        {/* {hasSponsors && <Advert message="Thanks to our sponsors" />} */}
      </div>
    );
  }
}

export default inject('stores')(observer(ScorecardScreen));
