import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Header, Loader, Statistic, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class AdminScreen extends Component {
  render() {
    const event = this.props.stores.events.activeEvent;
    const quiz = this.props.stores.events.activeQuiz;
    const teams = this.props.stores.teams.teams;

    const userCount = teams
      ? Array.from(teams.values()).reduce(
          (count, team) => count + team.users.length,
          0
        )
      : null;

    if (!quiz) {
      return <Loader />;
    }

    return (
      <div style={{ marginBottom: '2rem' }}>
        <Header as="h1" textAlign="center">
          <Header.Content>
            {event.name}
            <Header.Subheader>{quiz.title}</Header.Subheader>
          </Header.Content>
        </Header>

        <Divider />

        <Statistic.Group widths="four">
          <Statistic as={Link} to="/admin/teams">
            <Statistic.Value>{teams.length}</Statistic.Value>
            <Statistic.Label>Teams</Statistic.Label>
          </Statistic>

          <Statistic>
            <Statistic.Value>
              {userCount !== null ? userCount : '?'}
            </Statistic.Value>
            <Statistic.Label>Users</Statistic.Label>
          </Statistic>

          <Statistic as={Link} to="/admin/quiz">
            <Statistic.Value>{quiz.rounds.size}</Statistic.Value>
            <Statistic.Label>Rounds</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </div>
    );
  }
}

export default inject('stores')(observer(AdminScreen));
