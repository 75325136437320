import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Header,
  Segment,
  Button,
  Label,
  Loader,
  Progress,
  Grid,
  Confirm,
  Icon,
  Breadcrumb,
  Message
} from 'semantic-ui-react';

import Question from '../../components/question.panel.js';
import Answer from '../../components/answer.panel.js';
import VerifyAnswersPanel from '../../components/admin/verify.panel.js';
import ScoresPanel from '../../components/admin/scores.panel.js';
import CloseRoundModel from '../../components/admin/close.round.modal';
import ResetModel from '../../components/admin/reset.modal';

class AdminScreen extends Component {
  state = { confirmingPrev: false, confirmingCloseRound: false };

  ui = {
    initialising: { label: 'Initialising', color: 'grey' },
    ready: { label: 'Ready', color: 'grey' },
    open: { label: 'Open', color: 'green' },
    closed: { label: 'Closed', color: 'orange' },
    reviewing: { label: 'Reviewing', color: 'green' },
    finalised: { label: 'Finalised', color: 'grey' },
    paused: { label: 'Paused', color: 'grey' }
  };

  openPrevConfirmation = () => this.setState({ confirmingPrev: true });
  confirmedPrev = (e, value) => {
    this.setState({ confirmingPrev: false });
    this.transitionQuestion(e, value);
  };
  closePrevConfirmation = () => this.setState({ confirmingPrev: false });

  openCloseRoundConfirmation = () =>
    this.setState({ confirmingCloseRound: true });
  confirmedCloseRound = (e, value) => {
    this.setState({ confirmingCloseRound: false });
    this.transitionRound(e, value);
  };
  closeCloseRoundConfirmation = () =>
    this.setState({ confirmingCloseRound: false });

  transitionRound = (e, value) => {
    this.props.stores.events.transitionRound(value.transitionto);
  };

  nextRound = (e, value) => {
    this.props.stores.events.setRound(value.round);
  };

  transitionQuestion = (e, value) => {
    this.props.stores.events.transitionQuestion(value.question);
  };

  transitionAnswerReview = (e, value) => {
    this.props.stores.events.transitionAnswerReview(value.answer);
  };

  hasNextRound = () => {
    return this.props.stores.events.activeQuiz.rounds.has(
      (this.props.stores.events.activeEvent.progress.round + 1).toString()
    );
  };

  releaseScores = (e, value) => {
    this.props.stores.events.releaseScores(value.round);
  };

  progressColor = (value, total) => {
    const percent = value / total;
    if (percent > 0.8) return 'green';
    else if (percent > 0.6) return 'olive';
    else if (percent > 0.4) return 'yellow';
    else if (percent > 0.2) return 'orange';
    return 'red';
  };

  render() {
    const event = this.props.stores.events.activeEvent;
    const quiz = this.props.stores.events.activeQuiz;
    const teams = this.props.stores.teams.teams;
    const round = quiz ? this.props.stores.events.activeRound(quiz.id) : null;

    if (!quiz || !round) {
      return <Loader />;
    }

    const questionID =
      event.progress.phase === 'open'
        ? round.questions.get(event.progress.question.toString())
        : event.progress.phase === 'reviewing'
          ? round.questions.get(event.progress.reviewing.toString())
          : null;
    const scores = this.props.stores.events.scores;

    const questionCrumb =
      event.progress.phase === 'open' && event.progress.question > 0 ? (
        'Question ' + event.progress.question + ' of ' + round.questions.size
      ) : (
        <Label basic color={this.ui[event.progress.phase].color}>
          {this.ui[event.progress.phase].label}
        </Label>
      );

    const completedRoundCount = event.progress.round;
    const remainingRoundCount = quiz.rounds.size - event.progress.round;

    let header =
      'Round ' +
      event.progress.round +
      (event.progress.phase === 'open'
        ? ' Questions'
        : event.progress.phase === 'closed'
          ? ' Verification'
          : event.progress.phase === 'reviewing'
            ? ' Answers'
            : '');

    if (event.progress.phase === 'initialising') {
      header = 'Initialising quiz';
    }

    const totalTeams = teams.length;
    const teamsWithAnswer = questionID
      ? teams.filter(t => t.answers.has(questionID)).length
      : null;
    const teamsProgressBar = teamsWithAnswer ? (
      <Progress
        value={teamsWithAnswer}
        total={totalTeams}
        attached="top"
        color={this.progressColor(teamsWithAnswer, totalTeams)}
      />
    ) : null;

    //XXX this needs to be broken down into components
    return (
      <div style={{ marginBottom: '2rem' }}>
        {event.progress.phase === 'paused' && (
          <div>
            <Message icon warning>
              <Icon name="clock outline" />
              <Message.Content>
                <Message.Header>Quiz is paused</Message.Header>
                After completing {completedRoundCount} round
                {completedRoundCount !== 1 ? 's' : ''} and with{' '}
                {remainingRoundCount} round
                {remainingRoundCount !== 1 ? 's' : ''} remaining
              </Message.Content>
            </Message>
            <Grid stackable columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    fluid
                    onClick={this.transitionRound}
                    transitionto="finalised"
                  >
                    Go back to round {event.progress.round}
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    fluid
                    color="orange"
                    onClick={this.nextRound}
                    round={event.progress.round + 1}
                  >
                    Start next round
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
        {event.progress.phase !== 'paused' && (
          <div>
            <Segment.Group>
              <Segment>
                <ResetModel event={event} quiz={quiz}>
                  <Breadcrumb size="small">
                    {quiz.title && (
                      <Breadcrumb.Section>{quiz.title}</Breadcrumb.Section>
                    )}
                    {quiz.title && <Breadcrumb.Divider icon="right angle" />}
                    {event.progress.phase !== 'initialising' && (
                      <Breadcrumb.Section link>
                        Round {event.progress.round} of{' '}
                        {Math.max(
                          ...Array.from(quiz.rounds.keys()).map(x => {
                            return parseInt(x);
                          })
                        )}
                      </Breadcrumb.Section>
                    )}
                    {event.progress.phase !== 'initialising' && (
                      <Breadcrumb.Divider icon="right angle" />
                    )}
                    <Breadcrumb.Section link active>
                      {questionCrumb}
                    </Breadcrumb.Section>
                  </Breadcrumb>
                </ResetModel>
              </Segment>

              <Segment>
                <Header as="h2" textAlign="center">
                  <Header.Content>{header}</Header.Content>
                </Header>

                {event.progress.phase === 'initialising' && (
                  <div>
                    <Button
                      fluid
                      color="green"
                      onClick={this.transitionRound}
                      transitionto="ready"
                    >
                      Start quiz
                    </Button>
                  </div>
                )}

                {event.progress.phase === 'ready' && (
                  <div>
                    <Button
                      fluid
                      color="green"
                      onClick={this.transitionRound}
                      transitionto="open"
                    >
                      Start round
                    </Button>
                  </div>
                )}

                {event.progress.phase === 'open' && (
                  <div>
                    {event.progress.question === 0 && (
                      <Button
                        fluid
                        primary
                        onClick={this.transitionQuestion}
                        question={event.progress.question + 1}
                      >
                        Show first question
                      </Button>
                    )}
                    {event.progress.question > 0 && (
                      <Button.Group size="large" fluid widths="2">
                        <Button
                          onClick={this.openPrevConfirmation}
                          question={event.progress.question - 1}
                          disabled={event.progress.question <= 0}
                        >
                          <Icon name="arrow left" /> Previous
                        </Button>
                        <Confirm
                          header="Go back to the previous question"
                          content="Are you sure? Everyone's probably seen the question already."
                          cancelButton="Never mind"
                          confirmButton="Go back"
                          open={this.state.confirmingPrev}
                          onCancel={this.closePrevConfirmation}
                          onConfirm={this.confirmedPrev}
                          question={event.progress.question - 1}
                        />
                        <Button.Or />
                        {event.progress.question < round.questions.size && (
                          <Button
                            primary
                            onClick={this.transitionQuestion}
                            question={event.progress.question + 1}
                          >
                            Next <Icon name="arrow right" />
                          </Button>
                        )}
                        {event.progress.question >= round.questions.size && (
                          <Button
                            color="orange"
                            onClick={this.openCloseRoundConfirmation}
                          >
                            Close round {event.progress.round}
                          </Button>
                        )}
                        {event.progress.question >= round.questions.size && (
                          <CloseRoundModel
                            header={'Close round ' + event.progress.round}
                            round={round}
                            roundNumber={event.progress.round}
                            open={this.state.confirmingCloseRound}
                            onCancel={this.closeCloseRoundConfirmation}
                            onConfirm={this.confirmedCloseRound}
                          />
                        )}
                      </Button.Group>
                    )}
                  </div>
                )}

                {event.progress.phase === 'open' &&
                  event.progress.question > 0 && (
                    <Progress
                      value={event.progress.question}
                      total={round.questions.size}
                      attached="top"
                      color="blue"
                    />
                  )}
                {event.progress.phase === 'reviewing' &&
                  event.progress.reviewing > 0 && (
                    <Progress
                      value={event.progress.reviewing}
                      total={round.questions.size}
                      attached="top"
                      color="blue"
                    />
                  )}
              </Segment>
            </Segment.Group>
            {event.progress.phase === 'open' && (
              <div>
                {event.progress.question > 0 &&
                  questionID && (
                    <Question
                      id={questionID}
                      number={event.progress.question}
                      question={this.props.stores.quiz.questions.get(
                        questionID
                      )}
                      inputDisabled
                    >
                      {teamsProgressBar}
                    </Question>
                  )}
              </div>
            )}

            {event.progress.phase === 'closed' && (
              <VerifyAnswersPanel
                round={round}
                roundNumber={event.progress.round}
                transitionRound={this.transitionRound}
              />
            )}

            {event.progress.phase === 'reviewing' && (
              <div>
                {event.progress.reviewing === 0 && (
                  <Button
                    fluid
                    primary
                    onClick={this.transitionAnswerReview}
                    answer={event.progress.reviewing + 1}
                  >
                    Show first answer
                  </Button>
                )}
                {event.progress.reviewing > 0 && (
                  <Button.Group size="large" fluid widths="2">
                    <Button
                      onClick={this.transitionAnswerReview}
                      answer={event.progress.reviewing - 1}
                      disabled={event.progress.reviewing === 1}
                    >
                      <Icon name="arrow left" /> Previous
                    </Button>
                    <Button.Or />
                    {event.progress.reviewing < round.questions.size && (
                      <Button
                        primary
                        onClick={this.transitionAnswerReview}
                        answer={event.progress.reviewing + 1}
                        // disabled={event.progress.reviewing === round.questions.size}
                      >
                        Next <Icon name="arrow right" />
                      </Button>
                    )}
                    {event.progress.reviewing >= round.questions.size && (
                      <Button
                        color="orange"
                        onClick={this.transitionRound}
                        transitionto="finalised"
                      >
                        Finalise round {event.progress.round}
                      </Button>
                    )}
                  </Button.Group>
                )}
                {event.progress.reviewing > 0 &&
                  questionID && (
                    <Answer
                      id={questionID}
                      number={event.progress.reviewing}
                      question={this.props.stores.quiz.questions.get(
                        questionID
                      )}
                    />
                  )}
              </div>
            )}

            {event.progress.phase === 'finalised' && (
              <div>
                <ScoresPanel scores={scores} round={event.progress.round} />
                <Grid stackable columns={this.hasNextRound() ? 3 : 1}>
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        fluid
                        primary
                        onClick={this.releaseScores}
                        round={event.progress.round}
                        disabled={
                          event.progress.released >= event.progress.round
                        }
                      >
                        Release scores
                      </Button>
                    </Grid.Column>
                    {this.hasNextRound() && (
                      <Grid.Column>
                        <Button
                          fluid
                          primary
                          onClick={this.transitionRound}
                          transitionto="paused"
                        >
                          Take a break
                        </Button>
                      </Grid.Column>
                    )}
                    {this.hasNextRound() && (
                      <Grid.Column>
                        <Button
                          fluid
                          color="orange"
                          onClick={this.nextRound}
                          round={event.progress.round + 1}
                        >
                          Next round
                        </Button>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default inject('stores')(observer(AdminScreen));
