import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Header, Table, Icon, Image } from 'semantic-ui-react';

class SponsorsScreen extends Component {
  render() {
    const event = this.props.stores.events.activeEvent;
    const sponsors = this.props.stores.sponsor
      .sponsorsFromList(event.sponsors)
      .filter(s => s !== undefined);

    return (
      <div>
        <Header icon textAlign="center">
          <Header.Content>Sponsors</Header.Content>
        </Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Sponsor</Table.HeaderCell>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>Link</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sponsors &&
              sponsors
                .sort((a, b) => ('' + a.name).localeCompare(b.name))
                .map(sponsor => (
                  <Table.Row key={sponsor.id}>
                    <Table.Cell>
                      <Header size="small">
                        {/* <Icon name="settings" /> */}
                        <Header.Content>
                          {sponsor.name}
                          {sponsor.tagline && (
                            <Header.Subheader>
                              {sponsor.tagline}
                            </Header.Subheader>
                          )}
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      {sponsor.image && (
                        // <Icon name="image outline" />
                        <Image src={sponsor.image} size="small" />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {sponsor.url && (
                        <a
                          href={sponsor.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon name="external" />
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <small>{sponsor.id}</small>
                    </Table.Cell>
                  </Table.Row>
                ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default inject('stores')(observer(SponsorsScreen));
