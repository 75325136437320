import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Icon, Modal, Button, Image, Message } from 'semantic-ui-react';

class RemoveImageModal extends Component {
  state = { open: false };

  open = () => this.setState({ open: true });
  close = (e, data) => {
    this.setState({ open: false });
    if (data.action && data.action === 'remove') {
      this.props.removeImage();
    }
  };

  render() {
    const { open } = this.state;
    const { url, triggerIcon, triggerText } = this.props;

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button icon={!!triggerIcon}>
              {triggerIcon && <Icon name={triggerIcon} />} {triggerText}
            </Button>
          )
        }
        open={open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>Remove image from question?</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Message
              icon="warning sign"
              header="Warning"
              content="The image will be removed from the question and cannot be recovered after the question is saved."
              color="orange"
            />
            <Image src={url} size="small" centered />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close}>Cancel</Button>
          <Button color="orange" onClick={this.close} action="remove">
            <Icon name="trash alternate outline" /> Remove
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(RemoveImageModal);
