import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Segment, Header, Loader, Grid } from 'semantic-ui-react';

class ScoresPanel extends Component {
  render() {
    const { scores } = this.props;

    if (!scores) {
      return (
        <Segment textAlign="center">
          <Header as="h3" textAlign="center">
            <Header.Content>Scores</Header.Content>
          </Header>

          <Loader active inline="centered" />
        </Segment>
      );
    }

    const { round, presenting } = this.props;
    const teams = Array.from(scores.keys());

    const data = [];
    for (let team of teams) {
      const s = scores.get(team);
      if (s) {
        data.push({
          team: team,
          score: round ? s.rounds.get(round).score : s.total
        });
      }
    }
    data.sort((a, b) => b.score - a.score);

    var items = [];
    for (let d of data) {
      items.push(
        <Grid.Column stretched>
          <Segment style={{ padding: '1em 1em' }}>
            <Grid>
              <Grid.Column
                width={13}
                style={presenting ? { fontSize: '1.4em' } : {}}
              >
                {d.team.name}
              </Grid.Column>
              <Grid.Column
                width={3}
                textAlign="right"
                style={presenting ? { fontSize: '2em' } : {}}
              >
                {d.score}
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      );
    }

    return (
      <Grid stackable columns={3}>
        {items}
      </Grid>
    );
  }
}

export default inject('stores')(observer(ScoresPanel));
