import React, { Component } from 'react';
import { inject } from 'mobx-react';
import {
  Icon,
  Form,
  Button,
  Image,
  Placeholder,
  Segment
} from 'semantic-ui-react';

import QuestionImageModal from './question.image.modal';
import RemoveImageModal from './remove.image.modal';

class QuestionImageField extends Component {
  state = { imageLoaded: false };

  acknowledgeImageLoaded = e => this.setState({ imageLoaded: true });

  render() {
    const { imageLoaded } = this.state;
    const { url, changeImage, removeImage } = this.props;

    if (url) {
      return (
        <Form.Field className="imagePreview" width={8}>
          <Form.Field label="Image" />
          <Segment>
            {!imageLoaded && (
              <Placeholder>
                <Placeholder.Image rectangular />
              </Placeholder>
            )}

            <Image
              src={url}
              size="medium"
              centered
              onLoad={this.acknowledgeImageLoaded}
            />

            <div style={{ textAlign: 'center', marginTop: '1em' }}>
              <QuestionImageModal url={url} changeImage={changeImage}>
                <Button icon="edit" />
              </QuestionImageModal>
              <RemoveImageModal url={url} removeImage={removeImage}>
                <Button icon="trash" />
              </RemoveImageModal>
            </div>
          </Segment>
        </Form.Field>
      );
    } else {
      return (
        <QuestionImageModal changeImage={changeImage}>
          <Form.Button width={2} label="Image">
            <Icon.Group>
              <Icon name="image outline" />
              <Icon corner name="add" />
            </Icon.Group>
          </Form.Button>
        </QuestionImageModal>
      );
    }
  }
}

export default inject('stores')(QuestionImageField);
