import firebase from 'firebase';
import { observable, action } from 'mobx';

const checkInternetStatus = async () => {
  try {
    const online = await fetch('/util/network.status', { cache: 'no-store' });
    return online.status >= 200 && online.status < 300;
  } catch (err) {
    return false;
  }
};

var networkStore = {
  status: {
    network: true,
    internet: true,
    database: true
  },

  initialise() {
    //
    // Network status
    //
    this.setStatus('network', navigator.onLine);
    window.addEventListener('online', () => {
      this.setStatus('network', true);
    });
    window.addEventListener('offline', () => {
      this.setStatus('network', false);
    });

    //
    // Internet status
    //
    setInterval(async () => {
      if (this.status.database) {
        this.setStatus('internet', await checkInternetStatus());
      }
    }, 5000);

    //
    // Firebase status
    //
    var connectedRef = firebase.database().ref('.info/connected');
    connectedRef.on(
      'value',
      function(snap) {
        this.setStatus('database', snap.val() === true);
      }.bind(this)
    );
  },

  setStatus: action(function(type, status) {
    if (this.status[type] !== status) {
      this.status[type] = status;
      console.log(type, 'status:', this.status[type] ? 'ONLINE' : 'OFFLINE');
    }
  }),

  get error() {
    if (!this.status.network) return 'No network connection';
    if (!this.status.internet) return 'No internet connection';
    if (!this.status.database) return 'Database connection lost';
    return null;
  }
};

export default observable(networkStore);
