import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Button, Table, Icon } from 'semantic-ui-react';

class CloseRoundModal extends Component {
  render() {
    const { open, round, roundNumber, onCancel, onConfirm } = this.props;
    const teamsStore = this.props.stores.teams;
    const teams = teamsStore.teams;

    const questionIDs = Array.from(round.questions.values());
    const teamList = teams.map(t => {
      const numberOfAnswers = Array.from(t.answers.keys()).filter(a =>
        questionIDs.includes(a)
      ).length;
      const answerRatio = numberOfAnswers / questionIDs.length;
      return (
        <Table.Row
          key={t.id}
          warning={answerRatio < 1 && answerRatio > 0.5}
          negative={answerRatio <= 0.5}
        >
          <Table.Cell textAlign="center">
            {answerRatio < 1 &&
              answerRatio > 0.5 && (
                <Icon name="warning" style={{ marginRight: 0 }} />
              )}
            {answerRatio <= 0.5 && (
              <Icon name="warning circle" style={{ marginRight: 0 }} />
            )}
          </Table.Cell>
          <Table.Cell textAlign="center">{numberOfAnswers}</Table.Cell>
          <Table.Cell>{t.name}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Modal
        open={open}
        onClose={onCancel}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>Close round {roundNumber}</Modal.Header>
        <Modal.Content>
          <Table compact unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  Questions answered (from total of {questionIDs.length})
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{teamList}</Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel}>Never mind</Button>
          <Button onClick={onConfirm} color="orange" transitionto="closed">
            Close round {roundNumber}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(observer(CloseRoundModal));
