import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Message, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class QuizStartMessage extends Component {
  render() {
    const { onQuizPage } = this.props;
    const event = this.props.stores.events.activeEvent;
    if (event.progress.phase === 'initialising') {
      return (
        <Message icon color="grey">
          <Icon name="graduation" />
          <Message.Content>
            {/* <Message.Header>Welcome</Message.Header> */}
            Welcome! The quiz will be starting soon.
          </Message.Content>
        </Message>
      );
    } else if (event.progress.phase === 'ready') {
      return (
        <Message icon color="orange">
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Get Ready!</Message.Header>
            Round {event.progress.round} is about to start.{' '}
            {!onQuizPage && <Link to="/quiz">Open the quiz</Link>}
          </Message.Content>
        </Message>
      );
    } else if (event.progress.phase === 'open' && !onQuizPage) {
      return (
        <Message icon color="red">
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>
              Round {event.progress.round} has started!
            </Message.Header>
            <Link to="/quiz">Open the quiz</Link>
          </Message.Content>
        </Message>
      );
    } else {
      return null;
    }
  }
}
export default inject('stores')(QuizStartMessage);
