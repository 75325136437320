import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import {
  Loader,
  Header,
  Divider,
  Image,
  Checkbox,
  Label,
  Segment,
  Form,
  Icon,
  Button,
  Container
} from 'semantic-ui-react';

class PrintQuizScreen extends Component {
  ui = observable({
    showAnswers: false,
    showImages: true,
    mode: 'page' // normal, page, presentation
  });

  toggle = (e, { name }) => {
    this.ui[name] = !this.ui[name];
  };

  handleModeChange = (e, { value }) => (this.ui.mode = value);

  render() {
    const event = this.props.stores.events.activeEvent;
    const quiz = this.props.stores.events.activeQuiz;
    if (!quiz) {
      return (
        <Loader active inline="centered">
          Loading quiz
        </Loader>
      );
    }

    const totalRounds = quiz.rounds.size;
    let totalQuestions = 0;
    const lastRoundNumber = Math.max(
      ...Array.from(quiz.rounds.keys()).map(x => {
        return parseInt(x);
      })
    );

    const presenting = this.ui.mode === 'presentation';

    let rounds = [];
    for (let r of Array.from(quiz.rounds.entries()).sort(
      (a, b) => a[0] - b[0]
    )) {
      const roundNumber = parseInt(r[0]);
      const round = this.props.stores.quiz.rounds.get(r[1]);

      if (round) {
        const numberOfQuestions = round.questions.size;
        totalQuestions += numberOfQuestions;
        var questions = [];
        var answers = [];
        for (let q of Array.from(round.questions.entries()).sort(
          (a, b) => a[0] - b[0]
        )) {
          const questionNumber = parseInt(q[0]);
          const questionID = q[1];
          const question = this.props.stores.quiz.questions.get(questionID);
          let answer = null;
          if (this.ui.showAnswers) {
            if (question.type === 'truefalse') {
              answer = question.answer ? 'True' : 'False';
            } else if (question.type === 'multiple') {
              answer = question.options.get(question.answer);
            } else {
              answer = question.answer;
            }
          }

          if (question) {
            const options =
              question.type === 'multiple'
                ? Array.from(question.options.entries())
                    .sort((a, b) => a[0] - b[0])
                    .map((e, index) => (
                      <li key={question.id + '.option-' + index}>{e[1]}</li>
                    ))
                : null;

            questions.push(
              <div
                key={question.id}
                className={'question' + (presenting ? ' break' : '')}
                style={{ marginBottom: '2em' }}
              >
                {presenting && (
                  <Header size="medium" textAlign="center">
                    Round {roundNumber}
                  </Header>
                )}
                <ol start={questionNumber}>
                  <li>
                    {question.question}{' '}
                    {question.image && !this.ui.showImages && (
                      <Icon name="image" color="grey" />
                    )}
                    {options && <ul>{options}</ul>}
                  </li>
                </ol>
                {question.image && this.ui.showImages && (
                  <Image
                    src={question.image}
                    size={presenting ? 'medium' : 'small'}
                    centered
                  />
                )}
                {answer && !presenting && (
                  <Container textAlign="center">
                    <Label basic color="red">
                      Answer: {answer}
                    </Label>
                  </Container>
                )}
                {questionNumber === numberOfQuestions && (
                  <Container textAlign="center">
                    <Label
                      size={presenting ? 'huge' : 'medium'}
                      color="black"
                      basic
                    >
                      End of round {roundNumber}
                    </Label>
                  </Container>
                )}
              </div>
            );

            if (presenting && this.ui.showAnswers) {
              answers.push(
                <div
                  key={question.id + '.answer'}
                  className={'answer break'}
                  style={{ marginBottom: '2em' }}
                >
                  <Header size="medium" textAlign="center">
                    Round {roundNumber} Answers
                  </Header>
                  <ol start={questionNumber}>
                    <li>
                      {question.question}{' '}
                      {question.image && !this.ui.showImages && (
                        <Icon name="image" color="grey" />
                      )}
                      {options && <ul>{options}</ul>}
                    </li>
                  </ol>
                  {question.image && this.ui.showImages && (
                    <Image
                      src={question.image}
                      size={presenting ? 'medium' : 'small'}
                      centered
                    />
                  )}
                  <Container textAlign="center">
                    <Label basic color="red" size="massive">
                      Answer: {answer}
                    </Label>
                  </Container>
                </div>
              );
            }
          }
        }

        rounds.push(
          <div
            key={round.id}
            className={
              'round' + (this.ui.mode === 'page' || presenting ? ' break' : '')
            }
          >
            <Header size="medium" textAlign="center">
              Round {roundNumber}
              {round.title ? ': ' + round.title : ''}
              <Header.Subheader>
                {round.questions.size} questions
              </Header.Subheader>
            </Header>
            {questions}
            {presenting && this.ui.showAnswers && (
              <div className={'sec' + (presenting ? ' break' : '')}>
                <Header size="medium" textAlign="center">
                  Round {roundNumber} Answers
                </Header>
              </div>
            )}
            {presenting && this.ui.showAnswers && answers}
            {roundNumber === lastRoundNumber && (
              <Container textAlign="center">
                <Label
                  size={presenting ? 'huge' : 'medium'}
                  color="black"
                  basic
                >
                  End of quiz
                </Label>
              </Container>
            )}
            {roundNumber !== lastRoundNumber && !presenting && <Divider />}
          </div>
        );
      }
    }

    return (
      <div className={presenting ? 'presenting' : ''}>
        <Segment className="printFilters">
          <Form>
            <Form.Group inline>
              <label>Layout</label>
              <Form.Radio
                label="Normal"
                value="normal"
                checked={this.ui.mode === 'normal'}
                onChange={this.handleModeChange}
              />
              <Form.Radio
                label="With page breaks"
                value="page"
                checked={this.ui.mode === 'page'}
                onChange={this.handleModeChange}
              />
              <Form.Radio
                label="Presentation"
                value="presentation"
                checked={this.ui.mode === 'presentation'}
                onChange={this.handleModeChange}
              />
            </Form.Group>
            <Form.Group inline>
              <label>Show</label>
              <Form.Field>
                <Checkbox
                  label="Show answers"
                  name="showAnswers"
                  onChange={this.toggle}
                  checked={this.ui.showAnswers}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Show images"
                  name="showImages"
                  onChange={this.toggle}
                  checked={this.ui.showImages}
                />
              </Form.Field>
            </Form.Group>
            <Button
              content="Print"
              primary
              fluid
              onClick={() => window.print()}
            />
          </Form>
        </Segment>
        <Header
          icon
          textAlign="center"
          className={presenting ? 'sec break' : ''}
        >
          <Header.Content>{event.name}</Header.Content>
          <Header.Subheader>{quiz.title}</Header.Subheader>
          <Header.Subheader>
            {totalRounds} rounds &middot; {totalQuestions} questions
          </Header.Subheader>
        </Header>
        {rounds}
      </div>
    );
  }
}

export default inject('stores')(observer(PrintQuizScreen));
