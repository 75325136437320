import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Form, Input, Select } from 'semantic-ui-react';

class JoinForm extends Component {
  constructor(props) {
    super(props);
    this.state = { id: null, passcode: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { teamid, passcode } = this.state;
    const team = this.props.stores.teams.getTeam(teamid);

    //XXX this is inherently insecure but will do for now
    // need to work out how to combine anonymous auth with team passcode approach,
    // or migrate to user accounts representing team (and use multiple logins if possible)
    // --> should be able to do it by using cloud functions directly from the app
    // --> seperate public team details from private team details into different collections (with appropriate security rules)
    //    --> eg team names in public collection, passcode and answers in private collection
    // --> implement cloud function to 'log into team' passing team id and passcode and returning success
    //    --> function validates passcode against private team collection, and adds user to team if successful (and removes from any other team if necessary)
    // --> need to consider how to handle user initiated team creation as the user won't be able to write to private collection to create team details to set passcode
    //    --> easier to handle from admin
    //    --> or, could implement cloud function to handle it (rather than write direct to database)
    if (team && team.passcode === passcode) {
      this.props.stores.teams.addUserToTeam(teamid);
    }
    this.setState({ passcode: '' });
  };

  render() {
    const { teams, passcode } = this.props;
    const { teamid } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.Field width={10} required>
            <label>Team</label>
            <Select
              placeholder="Select your team"
              options={teams}
              name="teamid"
              value={teamid}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field width={6} required>
            <label>Passcode</label>
            <Input
              type="password"
              icon="user secret"
              iconPosition="left"
              placeholder="Code"
              name="passcode"
              size="large"
              value={passcode}
              onChange={this.handleChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Button
          fluid
          primary
          content="Join"
          style={{ marginTop: '2rem' }}
        />
      </Form>
    );
  }
}

export default inject('stores')(observer(JoinForm));
