import React from 'react';
import { Form, Select } from 'semantic-ui-react';

export default function QuestionTypeSelect(props) {
  return (
    <Form.Field
      control={Select}
      label="Type"
      value={props.questionType}
      options={props.options}
      onChange={props.changeType}
      placeholder="Type"
      width={props.width}
    />
  );
}
