import React from 'react';
import { Form } from 'semantic-ui-react';

export default function QuestionField(props) {
  return (
    <Form.TextArea
      label="Question"
      value={props.question}
      placeholder="Provide the question here..."
      onChange={props.changeQuestion}
      required
      width={props.width}
    />
  );
}
