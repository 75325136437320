import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';

class TextBanner extends Component {
  render() {
    const { sponsor } = this.props;

    return (
      <Header size="medium">
        <Header.Content>
          {sponsor.name}
          {sponsor.tagline && (
            <Header.Subheader>{sponsor.tagline}</Header.Subheader>
          )}
        </Header.Content>
      </Header>
    );
  }
}

export default TextBanner;
