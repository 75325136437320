import { observable, action } from 'mobx';
import firebase from 'firebase';

var eventsStore = observable({
  loading: true,
  events: observable(new Map()),
  user: null,

  db: firebase.firestore().collection('events'),
  closeDBSession: null,

  quizStore: null,
  teamsStore: null,
  sponsorStore: null,

  subscribe(user, quizStore, teamsStore, sponsorStore) {
    if (this.closeDBSession) this.unsubscribe();
    this.user = user;
    this.quizStore = quizStore;
    this.teamsStore = teamsStore;
    this.sponsorStore = sponsorStore;
    this.closeDBSession = this.db.onSnapshot(
      function (snapshot) {
        this.loading = false;
        if (snapshot.size) {
          snapshot.docChanges().forEach(
            function (change) {
              const id = change.doc.ref.id;
              const data = change.doc.data();
              data.id = id;
              // console.log(change.type + ': ' + id);
              // console.log(data);
              if (change.type === 'added' || change.type === 'modified') {
                this.events.set(id, data);
                this.quizStore.subscribe(data.quiz);

                if (data.sponsors) {
                  for (let sponsor of data.sponsors) {
                    this.sponsorStore.subscribe(sponsor);
                  }
                }
              } else if (change.type === 'removed') {
                //XXX this should unsubscribe from the relevant quiz first (be wary of multiple events referencing the same quiz)
                this.events.delete(id);

                //XXX unsubscribe from sponsors
              }
            }.bind(this)
          );
        }
      }.bind(this)
    );
  },

  unsubscribe() {
    this.quizStore.unsubscribe();
    this.events.clear();
    this.loading = true;
    if (this.closeDBSession) {
      this.closeDBSession();
    }
  },

  get activeEvent() {
    const event = Array.from(this.events.values()).find(e => e.progress.active);
    return event;
  },

  //XXX the quiz retrieval should really be rolled up into the event object
  get activeQuiz() {
    const event = this.activeEvent;
    return event ? this.quizStore.quiz(event.quiz) : null;
  },

  activeRound(quizID) {
    const event = this.activeEvent;
    const quiz = this.activeQuiz;
    return quiz
      ? this.quizStore.rounds.get(
          quiz.rounds.get(event.progress.round.toString())
        )
      : null;
  },

  get eventNames() {
    return Array.from(this.events.entries())
      .sort((a, b) => ('' + a[1].name).localeCompare(b[1].name))
      .map(function (t) {
        return { name: t[1].name, value: t[0] };
      });
  },

  eventExists: function (name) {
    return this.eventNames.find(n => n.name === name);
  },

  get scores() {
    if (this.teamsStore) {
      const teams = this.teamsStore.teams;
      var scores = new Map(); // team : scores
      for (let team of teams) {
        scores.set(team, this.teamScores(team));
      }
      return scores;
    }
    return null;
  },

  teamScores: function (team) {
    const event = this.activeEvent;
    if (event) {
      var numberOfRounds =
        event.progress.phase !== 'paused' &&
        event.progress.phase !== 'finalised' &&
        event.progress.phase !== 'reviewing' &&
        event.progress.phase !== 'closed'
          ? event.progress.round - 1
          : event.progress.round;

      const quiz = this.activeQuiz;
      if (quiz) {
        var scores = {
          rounds: new Map(), // round : { score, cumulative }
          total: 0
        };

        var cumulative = 0;
        var cumulativeMax = 0;

        for (var i = 0; i <= numberOfRounds; i++) {
          var roundScore = 0;
          const round = this.quizStore.rounds.get(
            quiz.rounds.get(i.toString())
          );
          if (round) {
            var roundMax = 0;
            for (let q of round.questions.values()) {
              // get team's answer and increment score if it's marked correct
              const answer = team.answers.get(q);
              if (answer && answer.correct) roundScore++;
              roundMax += 1;
            }
            cumulative += roundScore;
            cumulativeMax += roundMax;

            scores.rounds.set(i, {
              score: roundScore,
              roundMax: roundMax,
              cumulative: cumulative,
              cumulativeMax: cumulativeMax
            });
            scores.total = cumulative;
          }
        }
        return scores;
      }
    }
    return null;
  },

  transitionRound: action(function (newPhase) {
    const event = this.activeEvent;
    if (event) {
      const currentPhase = event.progress.phase;
      const validTransition =
        (currentPhase === 'initialising' && newPhase === 'ready') ||
        (currentPhase === 'ready' && newPhase === 'open') ||
        (currentPhase === 'open' && newPhase === 'closed') ||
        (currentPhase === 'closed' && newPhase === 'reviewing') ||
        (currentPhase === 'closed' && newPhase === 'finalised') ||
        (currentPhase === 'reviewing' && newPhase === 'finalised') ||
        (currentPhase === 'finalised' && newPhase === 'paused') ||
        (currentPhase === 'paused' && newPhase === 'finalised');

      if (validTransition) {
        var eventRef = this.db.doc(event.id);
        var updates = {};
        updates['progress.phase'] = newPhase;
        if (currentPhase === 'ready') {
          updates['progress.question'] = 0;
        }
        eventRef.update(updates);
      }
    }
  }),

  setRound: action(function (round) {
    this.setProgress(round, 0, 0, 'ready');
  }),

  //XXX this could also set 'released' to help with full reset
  setProgress: action(function (round, question, answerReview, phase) {
    const event = this.activeEvent;
    const quiz = this.activeQuiz;
    if (event && quiz && quiz.rounds.has(round.toString())) {
      var eventRef = this.db.doc(event.id);
      var updates = {};
      updates['progress.round'] = round;
      updates['progress.question'] = question;
      updates['progress.reviewing'] = answerReview;
      updates['progress.phase'] = phase;
      eventRef.update(updates);
    }
  }),

  transitionQuestion: action(function (number) {
    const event = this.activeEvent;
    //XXX check question number is valid
    if (event) {
      var eventRef = this.db.doc(event.id);
      var updates = {};
      updates['progress.question'] = number;
      eventRef.update(updates);
    }
  }),

  transitionAnswerReview: action(function (number) {
    const event = this.activeEvent;
    //XXX check question answer is valid
    if (event) {
      var eventRef = this.db.doc(event.id);
      var updates = {};
      updates['progress.reviewing'] = number;
      eventRef.update(updates);
    }
  }),

  releaseScores: action(function (round) {
    //XXX this should perhaps limit release of scores to be within progress round limits
    const event = this.activeEvent;
    const quiz = this.activeQuiz;
    if (event && quiz && quiz.rounds.has(round.toString())) {
      var eventRef = this.db.doc(event.id);
      var updates = {};
      updates['progress.released'] = round;
      eventRef.update(updates);
    }
  })
});

export default eventsStore;
