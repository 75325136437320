import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Container, Header, Segment, Icon } from 'semantic-ui-react';

import QuizStartMessage from '../../components/start.message';

class PasscodeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };

    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    return (
      <div>
        <QuizStartMessage />
        <Container textAlign="center">
          Your team name is...
          <Header as="h2" content={this.props.stores.teams.activeTeam.name} />
          And your team's passcode is...
          <Segment
            secondary
            onClick={this.toggleVisibility}
            style={{ backgroundColor: 'white' }}
          >
            <Header as="h2">
              <Header.Content>
                <Icon name="user secret" color="grey" />
                <Header.Subheader>
                  Click to {this.state.visible ? 'hide' : 'reveal'}
                </Header.Subheader>
                {this.state.visible && (
                  <p>{this.props.stores.teams.activeTeam.passcode}</p>
                )}
                {!this.state.visible && (
                  <p>&bull; &bull; &bull; &bull; &bull; &bull;</p>
                )}
              </Header.Content>
            </Header>
          </Segment>
          <p>
            Share it with the rest of your team if they want to join, but keep
            it to yourselves - you don't want someone else changing your
            answers, do you?
          </p>
          <p>
            <Link to="/">Back to the quiz</Link>
          </p>
        </Container>
      </div>
    );
  }
}

export default inject('stores')(observer(PasscodeScreen));
