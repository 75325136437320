import React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';

import Advert from '../components/sponsor/advert.panel';
import QuizStartMessage from '../components/start.message';

class SponsorsScreen extends Component {
  render() {
    const event = this.props.stores.events.activeEvent;
    const hasSponsors =
      event !== undefined &&
      event.sponsors !== undefined &&
      event.sponsors.length > 0;

    return (
      <div>
        <QuizStartMessage />
        {hasSponsors && <Advert message="Thanks to our sponsors" />}
      </div>
    );
  }
}

export default inject('stores')(observer(SponsorsScreen));
