import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { List, Icon } from 'semantic-ui-react';

class VerifyAnswer extends Component {
  //XXX this should be merged with answerString from verify.panel.js
  answerString(q, a) {
    if (!a) return null;
    if (q.type === 'truefalse') {
      return a.answer ? 'True' : 'False';
    } else if (q.type === 'multiple') {
      return q.options.get(a.answer);
    }
    return a.answer.toString();
  }

  store = this.props.stores.teams;
  handleClick = (e, data) =>
    this.store.verifyAnswer(
      this.props.teamID,
      this.props.question.id,
      this.props.answer.correct === undefined ||
        this.props.answer.correct === null
        ? false
        : !this.props.answer.correct
    );

  render() {
    const { question, answer } = this.props;

    if (answer === undefined) {
      return null;
    }

    ///XXX this causes a lag in the UI where isVerified returns false waiting
    // on the timestamp to update from the server (as I guess it can't be cached)
    // before again becoming true when the server responds
    // -> not a show stopper but should fix in the future
    const isVerified =
      answer.correct !== undefined &&
      answer.correct !== null &&
      answer.verifiedAt > answer.timestamp;
    const isCorrect = answer.correct;

    if (question) {
      return (
        <List.Item
          // as={!this.verifiable(question) && !isCorrect && answer ? 'a' : ''}
          as="a"
          onClick={this.handleClick}
        >
          <Icon
            name={
              (isVerified ? (isCorrect ? 'check' : 'times') : 'question') +
              ' circle outline'
            }
            color={isVerified ? (isCorrect ? 'olive' : 'red') : 'grey'}
            size="large"
          />
          <List.Content>
            <List.Header as="h4">
              {this.answerString(question, answer)}
            </List.Header>
          </List.Content>
        </List.Item>
      );
    }
  }
}

export default inject('stores')(observer(VerifyAnswer));
