import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import { Modal, Icon, Form, Button, Label } from 'semantic-ui-react';

import QuestionField from './question.field';
import QuestionImageField from './question.image.field';
import QuestionTypeField from './question.type.field';
import TextAnswerField from './answer.text.field';
import TrueFalseAnswerField from './answer.truefalse.field';
import MultipleChoiceAnswerField from './answer.multiplechoice.field';
import ReferencesField from './references.field';

class QuestionModal extends Component {
  ui = observable({
    open: false
  });

  draft = observable({});

  openModal = () => {
    const { question } = this.props;

    this.draft.question = question ? question.question : '';
    this.draft.type = this.props.question ? this.props.question.type : 'text';
    this.draft.image = question && question.image ? question.image : null;
    this.draft.answer = question ? question.answer : '';
    this.draft.options = observable(
      new Map(question ? question.options : null)
    );
    this.draft.reference =
      question && question.reference ? question.reference : '';

    this.ui.open = true;
  };
  closeModal = (e, data) => {
    if (data.action && data.action === 'save') {
      const q = {
        type: this.draft.type,
        question: this.draft.question,
        image: this.draft.image,
        answer: this.draft.answer,
        options:
          this.draft.options.size > 0 ? this.draft.options.toJSON() : null,
        reference: this.draft.reference ? this.draft.reference : null
      };

      if (this.props.question) {
        this.props.stores.quiz.updateQuestion(this.props.question.id, q);
      } else {
        this.props.stores.quiz.createQuestion(q, this.props.round);
      }
    }
    if (!this.props.question) {
      this.draft = observable({});
    }
    this.ui.open = false;
  };

  handleQuestionChange = (e, { value }) => (this.draft.question = value);

  handleTypeChange = (e, { value }) => (this.draft.type = value);

  changeImage = url => (this.draft.image = url);
  removeImage = () => (this.draft.image = null);

  handleAnswerChange = (e, { value }) =>
    (this.draft.answer =
      this.draft.type === 'truefalse' ? value === 'true' : value);

  handleOptionChange = (e, { index, value }) => {
    if (typeof value === 'string') {
      this.draft.options.set(index, value);
    }

    // if value is null then remove the option
    else if (!value) {
      // assumes existing indicies are sequential
      let indicies = Array.from(this.draft.options.keys()).sort();
      if (indicies.includes(index)) {
        indicies = indicies.slice(indicies.indexOf(index) + 1);
        if (indicies.length > 0) {
          indicies.forEach(i => {
            this.draft.options.set(i - 1, this.draft.options.get(i));
          });
          this.draft.options.delete(indicies.pop());
        } else {
          this.draft.options.delete(index);
        }

        if (this.draft.answer === index) this.draft.answer = null;
        else if (this.draft.answer > index)
          this.draft.answer = this.draft.answer - 1;
      }
    }
  };

  typeOptions = [
    { text: 'Free text', value: 'text', icon: 'terminal' },
    { text: 'True/False', value: 'truefalse', icon: 'toggle on' },
    { text: 'Multiple choice', value: 'multiple', icon: 'numbered list' }
  ];

  handleReferenceChange = (e, { value }) => (this.draft.reference = value);

  render() {
    const {
      round,
      roundNumber,
      question,
      triggerIcon,
      triggerText
    } = this.props;

    let header = round
      ? `${question ? 'Edit' : 'Add'} question ${
          question ? 'in' : 'to'
        } round ${roundNumber}`
      : `Create question`;

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button icon={!!triggerIcon}>
              {triggerIcon && <Icon name={triggerIcon} />} {triggerText}
            </Button>
          )
        }
        open={this.ui.open}
        onOpen={this.openModal}
        onClose={this.closeModal}
        closeOnDimmerClick={false}
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group>
                <QuestionField
                  question={this.draft.question}
                  width={this.draft.image ? 8 : 14}
                  changeQuestion={this.handleQuestionChange}
                />
                <QuestionImageField
                  url={this.draft.image}
                  changeImage={this.changeImage}
                  removeImage={this.removeImage}
                />
              </Form.Group>
              <QuestionTypeField
                questionType={this.draft.type}
                options={this.typeOptions}
                changeType={this.handleTypeChange}
                width={14}
              />
              {this.draft.type === 'text' && (
                <TextAnswerField
                  answer={this.draft.answer}
                  changeAnswer={this.handleAnswerChange}
                />
              )}
              {this.draft.type === 'truefalse' && (
                <TrueFalseAnswerField
                  answer={this.draft.answer}
                  changeAnswer={this.handleAnswerChange}
                />
              )}
              {this.draft.type === 'multiple' && (
                <MultipleChoiceAnswerField
                  options={this.draft.options}
                  changeOption={this.handleOptionChange}
                  answer={this.draft.answer}
                  changeAnswer={this.handleAnswerChange}
                />
              )}
              <ReferencesField
                label="References"
                reference={this.draft.reference}
                changeReference={this.handleReferenceChange}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {question && (
            <Label basic attached="bottom" size="mini">
              Round: {round ? round.id : 'N/A'} | Question: {question.id}
            </Label>
          )}
          <Button onClick={this.closeModal}>Cancel</Button>
          <Button positive onClick={this.closeModal} action="save">
            <Icon name="checkmark" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(observer(QuestionModal));
