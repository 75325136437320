import React, { Component } from 'react';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { Container, Divider, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class LoginScreen extends Component {
  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    credentialHelper: firebaseui.auth.CredentialHelper.NONE
  };

  render() {
    return (
      <Container text>
        <StyledFirebaseAuth
          uiConfig={this.uiConfig}
          firebaseAuth={firebase.auth()}
        />
        <Divider horizontal>OR</Divider>
        <Segment vertical textAlign="center">
          <Link to="/join">Join a team</Link>
        </Segment>
      </Container>
    );
  }
}

export default LoginScreen;
