import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Header,
  Segment,
  Grid,
  Form,
  Radio,
  Responsive
} from 'semantic-ui-react';

import QuestionImage from './image';

class MultipleChoiceQuestion extends Component {
  store = this.props.stores.teams;
  handleChange = (e, { value }) =>
    this.store.setAnswer(this.props.id, parseInt(value));

  render() {
    const { id, question, inputDisabled, presenting } = this.props;

    const answer =
      this.store.activeTeam && this.store.activeTeam.answers.has(this.props.id)
        ? this.store.activeTeam.answers.get(this.props.id).answer
        : null;

    const options = Array.from(question.options.entries())
      .sort((a, b) => a[0] - b[0])
      .map((e, index) => {
        const option = parseInt(e[0]);

        return (
          <Grid.Column key={id + '-' + option}>
            {!inputDisabled && (
              <Form.Field className="multipleChoice">
                <Segment
                  compact
                  inverted={answer === option}
                  color={answer === option ? 'blue' : null}
                >
                  <Radio
                    label={e[1]}
                    name="radioGroup"
                    value={option}
                    checked={answer === option}
                    onChange={this.handleChange}
                    style={{ fontSize: '1.15em' }}
                  />
                </Segment>
              </Form.Field>
            )}
            {inputDisabled && (
              <Segment style={{ fontSize: '1.15em' }}>
                <Grid>
                  <Responsive
                    as={Grid.Column}
                    minWidth={768}
                    tablet={3}
                    computer={2}
                    style={{ color: 'grey' }}
                  >
                    {index + 1}.
                  </Responsive>
                  <Grid.Column
                    mobile={16}
                    tablet={13}
                    computer={14}
                    style={
                      presenting
                        ? { fontSize: '2.5rem', lineHeight: '2.5rem' }
                        : {}
                    }
                  >
                    {e[1]}
                  </Grid.Column>
                </Grid>
              </Segment>
            )}
          </Grid.Column>
        );
      });

    var columns = options.length % 2 === 0 && options.length % 3 !== 0 ? 2 : 3;

    return (
      <div key={id} style={{ textAlign: 'center' }}>
        <Grid
          stackable
          columns={question.image ? 2 : 1}
          textAlign="center"
          verticalAlign="middle"
          style={{ marginBottom: 0 }}
        >
          <Grid.Column>
            <Header
              size={presenting ? 'huge' : 'medium'}
              style={presenting ? { fontSize: '3rem' } : {}}
            >
              {question.question}
            </Header>
          </Grid.Column>

          {question.image && (
            <Grid.Column>
              <QuestionImage src={question.image} presenting={presenting} />
            </Grid.Column>
          )}
        </Grid>
        <Form>
          <Grid stackable columns={columns}>
            {options}
          </Grid>
        </Form>
      </div>
    );
  }
}

export default inject('stores')(observer(MultipleChoiceQuestion));
