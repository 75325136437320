import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Loader,
  Header,
  Accordion,
  Icon,
  Button,
  Grid
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import QuestionItem from '../../components/admin/question.item';
import QuestionModal from '../../components/admin/question.modal';
import AddQuestionModal from '../../components/admin/add.question.modal';
import RemoveRoundModal from '../../components/admin/remove.round.modal';

//XXX need to consider reordering rounds
//XXX need to consider drap and drop for reordering questions and rounds
class RoundsAdminScreen extends Component {
  state = { activeIndex: 0, answertype: 'text', question: '', answer: '' };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  addRound = (e, data) => {
    this.props.stores.quiz.createRound(data.title, data.quiz);
  };

  moveQuestion = (question, round, index) => {
    this.props.stores.quiz.moveQuestionInRound(question, round, index);
  };

  render() {
    const quiz = this.props.stores.events.activeQuiz;

    if (!quiz) {
      return (
        <Loader active inline="centered">
          Loading quiz
        </Loader>
      );
    }

    const { activeIndex } = this.state;

    var totalRounds = quiz.rounds.size;
    var totalQuestions = 0;

    var rounds = [];
    for (let r of Array.from(quiz.rounds.entries()).sort(
      (a, b) => a[0] - b[0]
    )) {
      const roundNumber = r[0];
      const round = this.props.stores.quiz.rounds.get(r[1]);

      if (round) {
        const numberOfQuestions = round.questions.size;
        totalQuestions += numberOfQuestions;
        var questions = [];
        for (let q of Array.from(round.questions.entries()).sort(
          (a, b) => a[0] - b[0]
        )) {
          const questionNumber = parseInt(q[0]);
          const questionID = q[1];
          const question = this.props.stores.quiz.questions.get(questionID);

          if (question) {
            questions.push(
              <QuestionItem
                key={question.id}
                question={question}
                questionNumber={questionNumber}
                numberOfQuestions={numberOfQuestions}
                moveQuestion={this.moveQuestion}
                round={round}
                roundNumber={roundNumber}
              />
            );
          }
        }

        rounds.push(
          <Accordion.Title
            active={activeIndex === roundNumber}
            index={roundNumber}
            onClick={this.handleClick}
            key={round.id + '.title'}
            style={{ background: '#f3f4f5' }}
          >
            <Icon name="dropdown" />
            Round {roundNumber}
            {round.title ? ': ' + round.title : ''}
          </Accordion.Title>
        );
        rounds.push(
          <Accordion.Content
            active={activeIndex === roundNumber}
            key={round.id + '.content'}
          >
            <Accordion.Accordion style={{ marginBottom: '1.5em' }}>
              {questions}
            </Accordion.Accordion>
            <Button.Group basic color="blue">
              <AddQuestionModal
                quiz={quiz}
                round={round}
                roundNumber={roundNumber}
              >
                <Button icon="add circle" content="Add" />
              </AddQuestionModal>
              <QuestionModal round={round} roundNumber={roundNumber}>
                <Button icon="write" content="New" />
              </QuestionModal>
            </Button.Group>
            <RemoveRoundModal
              quiz={quiz}
              round={round}
              roundNumber={roundNumber}
            >
              <Button
                icon="trash alternate outline"
                content="Remove"
                floated="right"
                basic
                color="orange"
              />
            </RemoveRoundModal>
          </Accordion.Content>
        );
      }
    }

    return (
      <div>
        <Header icon textAlign="center">
          <Header.Content>{quiz.title}</Header.Content>
          <Header.Subheader>
            {totalRounds} rounds &middot; {totalQuestions} questions
          </Header.Subheader>
        </Header>
        <Accordion fluid styled>
          {rounds}
        </Accordion>
        <Grid style={{ marginTop: '0.5em' }}>
          <Grid.Column width={12}>
            <Button onClick={this.addRound} quiz={quiz}>
              <Icon name="add" />
              Add round
            </Button>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <Button.Group basic size="small">
              <Button icon="print" as={Link} to="/admin/print" />
            </Button.Group>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default inject('stores')(observer(RoundsAdminScreen));
