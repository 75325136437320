import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, Header, Button } from 'semantic-ui-react';

import QuestionImage from './image';

class TrueFalseQuestion extends Component {
  store = this.props.stores.teams;
  handleClick = (e, data) => this.store.setAnswer(this.props.id, data.value);

  render() {
    const { id, question, inputDisabled, presenting } = this.props;
    const answer =
      this.store.activeTeam && this.store.activeTeam.answers.has(this.props.id)
        ? this.store.activeTeam.answers.get(this.props.id).answer
        : null;

    return (
      <div key={id} style={{ textAlign: 'center' }}>
        <Grid
          stackable
          columns={question.image ? 2 : 1}
          textAlign="center"
          verticalAlign="middle"
          style={{ marginBottom: 0 }}
        >
          <Grid.Column>
            <Header
              size={presenting ? 'huge' : 'medium'}
              style={presenting ? { fontSize: '3rem' } : {}}
            >
              {question.question}
            </Header>
          </Grid.Column>

          {question.image && (
            <Grid.Column>
              <QuestionImage src={question.image} presenting={presenting} />
            </Grid.Column>
          )}
        </Grid>
        <Button.Group size="large" attached="bottom">
          <Button
            value={true}
            onClick={this.handleClick}
            primary={answer != null && answer === true}
            disabled={inputDisabled}
          >
            True
          </Button>
          <Button.Or />
          <Button
            value={false}
            onClick={this.handleClick}
            primary={answer != null && answer === false}
            disabled={inputDisabled}
          >
            False
          </Button>
        </Button.Group>
      </div>
    );
  }
}

export default inject('stores')(observer(TrueFalseQuestion));
