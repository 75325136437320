import firebase from 'firebase';
import uuidv1 from 'uuid/v1';

var filesStore = {
  uploadImage(file, onProgress, onSuccess, onError) {
    var imageStorageRef = firebase
      .storage()
      .ref()
      .child('images');

    // generate a unique ID for the image filename
    //XXX this should probable use uuidv5() with a custom namespace from the user id
    var id = uuidv1();
    var ext = /(?:\.([^.]+))?$/.exec(file.name)[1];

    var imageRef = imageStorageRef.child(id + '.' + ext);

    console.log(imageRef.fullPath);
    var uploadTask = imageRef.put(file);

    uploadTask.on(
      'state_changed',
      snapshot => {
        if (onProgress) {
          onProgress(snapshot);
        }
      },
      error => {
        if (onError) {
          onError(error);
        } else {
          console.error(error);
        }
      },
      () => {
        if (onSuccess) {
          uploadTask.snapshot.ref.getDownloadURL().then(url => {
            onSuccess(url);
          });
        }
      }
    );
  }
};

export default filesStore;
