import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import {
  Header,
  Message,
  Icon,
  Visibility,
  Button,
  Segment,
  Label
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import Question from '../components/question.panel.js';
import Answer from '../components/answer.panel.js';
import Advert from '../components/sponsor/advert.panel.js';
import QuizStartMessage from '../components/start.message';
import Status from '../components/status/status.footer';

class QuizScreen extends Component {
  ui = observable({
    // visibility flags for current active question
    visibility: {}
  });

  handleUpdate = (e, { calculations }) => {
    // only update visibility state if it has actually changed to prevent
    // continuous re-renders due to Visibility updateOn="repaint" option
    if (this.ui.visibility.offScreen !== calculations.offScreen) {
      this.ui.visibility.offScreen = calculations.offScreen;
    }
    if (this.ui.visibility.bottomPassed !== calculations.bottomPassed) {
      this.ui.visibility.bottomPassed = calculations.bottomPassed;
    }
  };

  scrollToCurrentQuestion() {
    var element = document.getElementById('currentQuestion');
    var headerOffset = 100;
    var elementPosition = element.getBoundingClientRect().top + window.scrollY;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  prevPhase = null;
  prevProgressReleased = 0;

  phaseChangedTo() {
    const currPhase = this.props.stores.events.activeEvent.progress.phase;
    return currPhase !== this.prevPhase ? currPhase : null;
  }

  // scrolls to top of screen when required for specific state changes
  componentDidUpdate(prevProps) {
    const event = this.props.stores.events.activeEvent;

    if (['open', 'closed', 'paused'].includes(this.phaseChangedTo())) {
      window.scrollTo(0, 0);
    }
    this.prevPhase = event.progress.phase;

    if (
      ['closed', 'reviewing', 'finalised'].includes(event.progress.phase) &&
      this.prevProgressReleased !== event.progress.released
    ) {
      window.scrollTo(0, 0);
    }
    this.prevProgressReleased = event.progress.released;
  }

  render() {
    const { events, teams, quiz, network } = this.props.stores;
    const event = events.activeEvent;
    const team = teams.activeTeam;
    const quizID = events.activeQuiz ? events.activeQuiz.id : null;
    const hasSponsors =
      event !== undefined &&
      event.sponsors !== undefined &&
      event.sponsors.length > 0;

    if (!event || !event.progress || !quizID) {
      return (
        <div className="questions">
          <p>The quiz is not ready yet</p>
        </div>
      );
    }

    const activeRound = events.activeRound(quizID);
    if (!activeRound) {
      return (
        <div className="questions">
          <p>No active round</p>
        </div>
      );
    }

    var questions = [];
    let answered = null;
    if (event.progress.phase === 'open') {
      answered = 0;
      Array.from(activeRound.questions.entries())
        .sort((a, b) => a[0] - b[0])
        .forEach(e => {
          const id = e[1];
          const question = quiz.questions.get(id);
          const number = parseInt(e[0]);
          const active = number <= event.progress.question;
          const current = number === event.progress.question;
          const preload = question && !active ? question.image : null;
          const questionPanel = (
            <Question
              id={id}
              key={id}
              number={number}
              current={current}
              question={active ? question : null}
              preload={preload}
            />
          );
          if (current) {
            questions.push(
              <Visibility
                offset={[195, 50]}
                updateOn="repaint" //required for ensuring scroll button is displayed on page refresh
                onUpdate={this.handleUpdate}
                key={'viz-' + id}
              />
            );
          }
          questions.push(questionPanel);
          if (active && team.answers.has(id)) answered++;
        });
    }

    const status = ['closed', 'reviewing', 'finalised'].includes(
      event.progress.phase
    )
      ? 'Closed'
      : event.progress.phase.charAt(0).toUpperCase() +
        event.progress.phase.slice(1);

    var score = null;
    if (event.progress.released >= event.progress.round) {
      const team = teams.activeTeam;
      const scores = events.teamScores(team);
      const progress = event.progress.round;
      score = scores.rounds.get(progress);
    }

    const questionID =
      event.progress.phase === 'reviewing'
        ? activeRound.questions.get(event.progress.reviewing.toString())
        : null;

    //XXX this should be broken up into different subcomponents for each quiz phase, ie open, closed, etc
    return (
      <div className="questions" style={{ paddingBottom: '3em' }}>
        <QuizStartMessage onQuizPage />
        {event.progress.phase === 'initialising' && (
          <Advert message="Thanks to our sponsors" />
        )}

        {event.progress.phase === 'open' &&
          activeRound.questions && (
            <div>
              {this.ui.visibility.offScreen && (
                <Button
                  circular
                  secondary
                  size="large"
                  icon={
                    this.ui.visibility.bottomPassed ? 'arrow up' : 'arrow down'
                  }
                  style={{
                    position: 'fixed',
                    bottom: '3em',
                    right: '0.3em',
                    zIndex: 100
                  }}
                  onClick={() => {
                    this.scrollToCurrentQuestion();
                  }}
                />
              )}
              <Header icon textAlign="center">
                <Header.Content>Round {event.progress.round}</Header.Content>

                {activeRound.title && (
                  <Header.Content as="h3">{activeRound.title}</Header.Content>
                )}
                <Header.Subheader>
                  <i>
                    {activeRound.questions.size} question
                    {activeRound.questions.size > 1 ? 's' : ''}
                  </i>
                </Header.Subheader>
              </Header>
              {questions}
            </div>
          )}
        {['closed', 'finalised'].includes(event.progress.phase) && (
          <div>
            {/* {score !== null && (
              <Segment textAlign="center">
                <Label attached="top" color="grey">
                  Score
                </Label>
                <Statistic>
                  <Statistic.Value>{score.score}</Statistic.Value>
                  <Statistic.Label>
                    Point
                    {score.score !== 1 ? 's' : ''}{' '}
                    <span style={{ color: 'grey', fontSize: '0.9rem' }}>
                      / {score.roundMax}
                    </span>
                  </Statistic.Label>
                </Statistic>
                <p>
                  <Link to="/scorecard">Team Scorecard</Link>
                </p>
              </Segment>
            )} */}

            {hasSponsors && <Advert message="Thanks to our sponsors" />}
          </div>
        )}
        {event.progress.phase === 'reviewing' && (
          <div>
            {hasSponsors && (
              <Segment>
                <Label
                  color="blue"
                  style={{
                    position: 'absolute',
                    top: '-1.3em',
                    left: '-1em'
                  }}
                >
                  Thanks to our sponsors
                </Label>
                <Advert compact />
              </Segment>
            )}
            {event.progress.reviewing > 0 &&
              questionID && (
                <Answer
                  id={questionID}
                  number={event.progress.reviewing}
                  question={quiz.questions.get(questionID)}
                  correct={
                    team.answers.get(questionID) &&
                    team.answers.get(questionID).correct
                  }
                />
              )}
          </div>
        )}
        {event.progress.phase === 'paused' && (
          <div>
            <Message icon>
              <Icon name="clock outline" />
              <Message.Content>
                <Message.Header>We're taking a break</Message.Header>
                But stick around as the next round will be starting soon
                <p>
                  <Link to="/scorecard">Team Scorecard</Link>
                </p>
              </Message.Content>
            </Message>
            {hasSponsors && <Advert message="Thanks to our sponsors" />}
          </div>
        )}
        {!network.error &&
          event.progress.phase !== 'initialising' && (
            <Status
              round={event.progress.round}
              status={status}
              questions={event.progress.question}
              totalQuestions={activeRound.questions.size}
              answers={answered}
              score={score}
            />
          )}
      </div>
    );
  }
}

export default inject('stores')(observer(QuizScreen));
