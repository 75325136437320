import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Grid,
  Header,
  Form,
  Input,
  Icon,
  Button,
  Label
} from 'semantic-ui-react';

import QuestionImage from './image';

class TextQuestion extends Component {
  constructor(props) {
    super();
    this.store = props.stores.teams;
    this.submit = this.submit.bind(this);
  }

  state = {};

  static getDerivedStateFromProps(props, state) {
    if (!props.draft) {
      return { draft: '' };
    } else if (props.draft !== state.draft) {
      return { draft: props.draft };
    } else return null;
  }

  handleChange = (e, data) =>
    this.store.setDraftAnswer(this.props.id, data.value);

  submit = e => {
    if (this.state.draft) {
      this.store.setAnswer(this.props.id, this.state.draft);
      this.store.clearDraftAnswer(this.props.id);
    }
  };

  render() {
    const { id, question, inputDisabled, presenting } = this.props;
    const answer =
      this.store.activeTeam && this.store.activeTeam.answers.has(this.props.id)
        ? this.store.activeTeam.answers.get(this.props.id).answer
        : null;

    return (
      <div key={id} style={{ textAlign: 'center' }}>
        <Grid
          stackable
          columns={question.image ? 2 : 1}
          textAlign="center"
          verticalAlign="middle"
          style={{ marginBottom: 0 }}
        >
          <Grid.Column>
            <Header
              size={presenting ? 'huge' : 'medium'}
              style={presenting ? { fontSize: '3rem' } : {}}
            >
              {question.question}
            </Header>
          </Grid.Column>

          {question.image && (
            <Grid.Column>
              <QuestionImage src={question.image} presenting={presenting} />
            </Grid.Column>
          )}
        </Grid>
        {answer && (
          <div style={{ marginBottom: '0.5em' }}>
            Your current answer is{' '}
            <Label color="blue" size="large" pointing="left" content={answer} />
          </div>
        )}
        {!inputDisabled && (
          <div>
            <Form onSubmit={this.submit}>
              <Input
                iconPosition="left"
                placeholder={answer ? 'Change answer...' : 'Answer...'}
                onChange={this.handleChange}
                style={{ width: '100%' }}
                size="large"
              >
                <Icon name="pencil" />
                <input value={this.state.draft} />
              </Input>
            </Form>
            <Button
              onClick={this.submit}
              style={{ marginTop: '1em', width: '100%' }}
              color={this.state.draft !== '' ? 'yellow' : null}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default inject('stores')(observer(TextQuestion));
