import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyD1cx73iOKRFGTZrgmfxjDFrUWOH6nc6H8',
  // authDomain: 'quiz-night-264da.firebaseapp.com',
  authDomain: 'localhost:3000',
  databaseURL: 'https://quiz-night-264da.firebaseio.com',
  projectId: 'quiz-night-264da',
  storageBucket: 'quiz-night-264da.appspot.com',
  messagingSenderId: '461032239437'
};

firebase.initializeApp(config);

const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);
