import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';

import stores from './stores';
import { Provider, observer } from 'mobx-react';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

import { Container } from 'semantic-ui-react';
import MainMenu from './components/menu/main.menu';
import AdminMenu from './components/menu/admin.menu';
import Join from './screens/team/join.screen';
import Login from './screens/login.screen';
import Passcode from './screens/team/passcode.screen';
import Quiz from './screens/quiz.screen';
import Scorecard from './screens/scorecard.screen';
import Sponsors from './screens/sponsors.screen';

import Dashboard from './screens/admin/dashboard.screen';
import QuizAdmin from './screens/admin/quiz.screen';
import Teams from './screens/admin/teams.screen';
import Scores from './screens/admin/scores.screen';
import RoundsAdmin from './screens/admin/rounds.screen';
import SponsorsAdmin from './screens/admin/sponsors.screen';
import Presentation from './screens/presentation.screen';
import Print from './screens/admin/print.screen';

import Status from './components/status/status.footer';

class App extends Component {
  render() {
    const network = stores.network;
    const admin = stores.auth.isAdmin;
    const team = stores.teams.activeTeam;
    const event = stores.events.activeEvent;
    const hasSponsors =
      event !== undefined &&
      event.sponsors !== undefined &&
      event.sponsors.length > 0;

    const menu = admin ? (
      <AdminMenu event={event} />
    ) : (
      <MainMenu event={event} team={team} />
    );

    return (
      <Provider stores={stores}>
        <div className="App" id="App">
          {menu}

          <Container className="main">
            {admin && (
              <Switch>
                <Route exact path="/admin/dashboard" component={Dashboard} />
                <Route exact path="/admin/quiz" component={QuizAdmin} />
                <Route exact path="/admin/teams" component={Teams} />
                <Route exact path="/admin/scores" component={Scores} />
                <Route exact path="/admin/questions" component={RoundsAdmin} />
                <Route exact path="/admin/sponsors" component={SponsorsAdmin} />
                <Route exact path="/admin/print" component={Print} />
                <Route exact path="/present" component={Presentation} />
                <Redirect from="/*" to="/admin/dashboard" />
              </Switch>
            )}

            {team && (
              <Switch>
                <Redirect exact from="/" to="/quiz" />
                <Route exact path="/quiz" component={Quiz} />
                <Route exact path="/passcode" component={Passcode} />
                <Route exact path="/scorecard" component={Scorecard} />
                {hasSponsors && (
                  <Route exact path="/sponsors" component={Sponsors} />
                )}
                <Redirect from="/*" to="/" />
              </Switch>
            )}

            {!team &&
              !admin && (
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/present" component={Presentation} />
                  {hasSponsors && (
                    <Route exact path="/sponsors" component={Sponsors} />
                  )}
                  <Route exact path="/" component={Join} />
                  <Redirect from="/*" to="/" />
                </Switch>
              )}
          </Container>
          <img
            src="images/icons/image-placeholder.svg"
            alt="preloaded placeholder"
            style={{ display: 'none' }}
          />
          {network.error && <Status error={network.error} />}
        </div>
      </Provider>
    );
  }
}

export default withRouter(observer(App));
