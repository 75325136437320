import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Header, Grid, Image } from 'semantic-ui-react';

import AnswerLabel from './answer.label.js';

class MultipleChoiceAnswer extends Component {
  render() {
    const { id, question, correct, presenting } = this.props;

    return (
      <div key={id} style={{ textAlign: 'center' }}>
        <Grid
          stackable
          columns={question.image ? 2 : 1}
          textAlign="center"
          verticalAlign="middle"
          style={{ marginBottom: 0 }}
        >
          <Grid.Row>
            <Grid.Column>
              <Header
                size={presenting ? 'huge' : 'medium'}
                style={presenting ? { fontSize: '3rem' } : {}}
              >
                {question.question}
              </Header>
              <AnswerLabel
                answer={question.options.get(question.answer)}
                correct={correct}
                presenting={presenting}
              />
            </Grid.Column>

            {question.image && (
              <Grid.Column>
                <Image
                  src={question.image}
                  centered
                  size={presenting ? 'big' : 'medium'}
                  style={
                    presenting
                      ? {
                          marginBottom: '1.5rem',
                          maxHeight: '70vh',
                          width: 'auto',
                          height: 'auto'
                        }
                      : { marginBottom: '1.5rem' }
                  }
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default observer(MultipleChoiceAnswer);
