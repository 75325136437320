import { observable } from 'mobx';
import firebase from 'firebase';

import * as moment from 'moment';

var authStore = observable({
  user: null,
  roles: new Map(),

  db: firebase.firestore().collection('users'),
  closeDBSession: null,

  init() {
    firebase.auth().onAuthStateChanged(user => {
      if (user === null) {
        firebase
          .auth()
          .signInAnonymously()
          .catch(function(error) {
            var errorCode = error.code;
            // var errorMessage = error.message;
            if (errorCode === 'auth/operation-not-allowed') {
              alert('You must enable Anonymous auth in the Firebase Console.');
            } else {
              console.error(error);
            }
          });
      } else {
        this.user = user;
        console.log('User: ' + this.user.uid);
      }
    });

    this.closeDBSession = this.db.onSnapshot(
      function(snapshot) {
        snapshot.docChanges().forEach(
          function(change) {
            const id = change.doc.ref.id;
            const data = change.doc.data();
            // console.log(change.type + ': ' + id);
            // console.log(data);
            if (change.type === 'added' || change.type === 'modified') {
              this.roles.set(id, data.role);
            } else if (change.type === 'removed') {
              this.roles.delete(id);
            }
          }.bind(this)
        );
      }.bind(this)
    );
  },

  signIn(email, password) {
    if (this.authUser) {
      return Promise.resolve(this.authUser);
    }
    return firebase.auth().signInWithEmailAndPassword(email, password);
  },

  signOut() {
    firebase.auth().signOut();
  },

  get isSignedIn() {
    return this.user != null;
  },

  get isAnonymous() {
    return this.user && this.user.isAnonymous;
  },

  get isAdmin() {
    return this.user && this.roles.get(this.user.uid) === 'admin';
  },

  get profile() {
    return this.user
      ? {
          uid: this.user.uid,
          email: this.user.email,
          displayName: this.user.displayName,
          photoURL: this.user.photoURL,
          createdAt: moment(this.user.metadata.creationTime),
          lastSignInTime: moment(this.user.metadata.lastSignInTime)
        }
      : null;
  }
});
authStore.init();

export default authStore;
