import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Menu, Dropdown, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import screenfull from 'screenfull';

class AdminMenu extends Component {
  constructor() {
    super();
    this.state = { isFullscreen: false };
  }

  componentDidMount = () => {
    if (screenfull.enabled) {
      this.setState({ isFullscreen: screenfull.isFullscreen });
      screenfull.on('change', this.updateFullscreenState);
    }
  };

  componentWillUnmount = () => {
    if (screenfull.enabled) {
      screenfull.off('change', this.updateFullscreenState);
    }
  };

  updateFullscreenState = () => {
    this.setState({ isFullscreen: screenfull.isFullscreen });
  };

  toggleFullscreen = () => {
    if (screenfull.enabled) screenfull.toggle();
  };

  handleSignOut = () => {
    if (this.props.stores.auth.user) {
      if (this.props.stores.auth.isAnonymous) {
        this.props.stores.teams.removeUserFromTeam();
      } else {
        this.props.stores.auth.signOut();
      }
    }
  };

  render() {
    const { event } = this.props;

    return (
      <Menu borderless fixed="top">
        <Menu.Item as={Link} to="/">
          <Header as="h3">
            <Icon name="graduation" />
            <Header.Content>{event ? event.name : 'Quiz App'}</Header.Content>
          </Header>
        </Menu.Item>

        <Menu.Menu position="right">
          <Dropdown item icon="bars">
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to="/admin/dashboard"
                icon="chart bar"
                text="Dashboard"
              />
              <Dropdown.Item
                as={Link}
                to="/admin/quiz"
                icon="graduation"
                text="Quiz"
              />
              <Dropdown.Item
                as={Link}
                to="/admin/scores"
                icon="trophy"
                text="Scores"
              />
              <Dropdown.Item
                as={Link}
                to="/admin/teams"
                icon="users"
                text="Teams"
              />
              <Dropdown.Divider />
              <Dropdown.Item
                as={Link}
                to="/admin/questions"
                icon="cog"
                text="Quiz setup"
              />
              <Dropdown.Item
                as={Link}
                to="/admin/sponsors"
                icon="gift"
                text="Sponsors"
              />
              <Dropdown.Divider />
              <Dropdown.Item
                as={Link}
                to="/present"
                icon="tv"
                text="Presentation mode"
              />
              {screenfull.enabled && (
                <Dropdown.Item
                  icon={this.state.isFullscreen ? 'compress' : 'expand'}
                  text={
                    this.state.isFullscreen ? 'Exit fullscreen' : 'Fullscreen'
                  }
                  onClick={this.toggleFullscreen}
                />
              )}
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={this.handleSignOut}
                icon="sign out"
                text="Sign out"
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
}

export default inject('stores')(observer(AdminMenu));
