import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import {
  Icon,
  Modal,
  Button,
  Dropdown,
  Grid,
  Message
} from 'semantic-ui-react';

class ResetModal extends Component {
  ui = observable({
    open: false
  });

  newProgress = observable({});

  open = () => {
    this.ui.open = true;
    this.newProgress.phase = this.props.event.progress.phase;
    this.newProgress.round = this.props.event.progress.round;
  };

  close = (e, data) => {
    this.ui.open = false;
    if (data.action && data.action === 'resetRound') {
      this.props.stores.events.setProgress(
        this.newProgress.round,
        0,
        0,
        'ready'
      );
    } else if (data.action && data.action === 'setRound') {
      this.props.stores.events.setProgress(
        this.newProgress.round,
        this.newProgress.phase !== 'ready'
          ? this.newProgress.round === this.props.event.progress.round
            ? this.props.event.progress.question
            : 1
          : 0,
        0,
        this.newProgress.phase
      );
    } else if (data.action && data.action === 'resetQuiz') {
      this.props.stores.events.setProgress(1, 0, 0, 'initialising');
    }
  };

  changePhase = (event, { value }) => (this.newProgress.phase = value);
  changeRound = (event, { value }) => (this.newProgress.round = value);

  roundPhases = {
    initialising: { text: 'Initialising', value: 'initialising' },
    ready: { text: 'Ready', value: 'ready' },
    open: { text: 'Open', value: 'open' },
    closed: { text: 'Closed', value: 'closed' },
    reviewing: { text: 'Reviewing', value: 'reviewing' },
    finalised: { text: 'Finalised', value: 'finalised' },
    paused: { text: 'Paused', value: 'paused' }
  };

  roundPhaseOptions = [
    this.roundPhases.initialising,
    this.roundPhases.ready,
    this.roundPhases.open,
    this.roundPhases.closed,
    this.roundPhases.reviewing,
    this.roundPhases.finalised,
    this.roundPhases.paused
  ];

  render() {
    const { open } = this.ui;
    const { event, quiz, triggerIcon, triggerText } = this.props;

    let roundOptions = Array.from(quiz.rounds.entries()).map(r => {
      return { text: 'Round ' + r[0], value: parseInt(r[0]) };
    });

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button icon={!!triggerIcon}>
              {triggerIcon && <Icon name={triggerIcon} />} {triggerText}
            </Button>
          )
        }
        open={open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>Reset</Modal.Header>
        <Modal.Content>
          <Message>
            <Modal.Description>
              <Grid stackable>
                <Grid.Column width={12}>
                  <Message.Header>
                    Start round {event.progress.round} again from the beginning
                  </Message.Header>
                  <p>
                    Note this will not remove or modify existing answers for
                    teams
                  </p>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  <Button
                    color="orange"
                    fluid
                    action="resetRound"
                    onClick={this.close}
                  >
                    Reset round {event.progress.round}
                  </Button>
                </Grid.Column>
              </Grid>
            </Modal.Description>
          </Message>
          <Message warning>
            <Modal.Description>
              <Grid stackable>
                <Grid.Column width={12}>
                  <Message.Header>
                    Set the quiz to be{' '}
                    <Dropdown
                      inline
                      options={this.roundPhaseOptions}
                      value={
                        this.newProgress.phase
                          ? this.roundPhases[this.newProgress.phase].value
                          : null
                      }
                      onChange={this.changePhase}
                    />
                    at{' '}
                    <Dropdown
                      inline
                      scrolling
                      options={roundOptions}
                      value={this.newProgress.round}
                      onChange={this.changeRound}
                    />
                  </Message.Header>
                  <p>
                    Note this will not remove or modify existing answers for
                    teams
                  </p>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  <Button
                    color="orange"
                    fluid
                    action="setRound"
                    onClick={this.close}
                  >
                    Set Quiz Status
                  </Button>
                </Grid.Column>
              </Grid>
            </Modal.Description>
          </Message>
          <Message error>
            <Modal.Description>
              <Grid stackable>
                <Grid.Column width={12}>
                  <Message.Header>
                    Reset the quiz to the very beginning
                  </Message.Header>
                  <p>
                    Note this will not remove or modify existing answers for
                    teams
                  </p>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  <Button
                    color="red"
                    fluid
                    action="resetQuiz"
                    onClick={this.close}
                  >
                    Reset quiz
                  </Button>
                </Grid.Column>
              </Grid>
            </Modal.Description>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(observer(ResetModal));
