import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Loader,
  Progress,
  Header,
  Icon,
  Segment,
  Grid
} from 'semantic-ui-react';
import QRCode from 'qrcode.react';

import Question from '../components/question.panel.js';
import Answer from '../components/answer.panel.js';
import ScoresPanel from '../components/admin/scores.panel.js';
import Advert from '../components/sponsor/advert.panel.js';

class PresentationScreen extends Component {
  render() {
    const event = this.props.stores.events.activeEvent;
    const quiz = this.props.stores.events.activeQuiz;
    const round = quiz ? this.props.stores.events.activeRound(quiz.id) : null;

    if (!event || !quiz || !round) {
      return <Loader />;
    }

    const hasSponsors = event.sponsors && event.sponsors.length > 0;

    const questionID =
      event.progress.phase === 'open'
        ? round.questions.get(event.progress.question.toString())
        : event.progress.phase === 'reviewing'
          ? round.questions.get(event.progress.reviewing.toString())
          : null;

    const scores = this.props.stores.events.scores;

    var message = null;
    if (
      event.progress.phase === 'ready' ||
      (event.progress.phase === 'open' && event.progress.question === 0)
    ) {
      message = (
        <Header size="huge" icon textAlign="center">
          <Icon name="warning sign" color="orange" />
          <Header.Content>
            Round {event.progress.round} is about to start
          </Header.Content>
        </Header>
      );
    } else if (
      event.progress.phase === 'closed' ||
      event.progress.phase === 'paused' ||
      (event.progress.phase === 'finalised' &&
        event.progress.released < event.progress.round &&
        hasSponsors)
    ) {
      message = <Advert message="Thanks to our sponsors" />;
    }

    return (
      <div style={{ marginBottom: '2rem' }}>
        {message}

        {event.progress.phase === 'initialising' && (
          <div>
            <Segment placeholder>
              <Grid columns={2} stackable textAlign="center">
                <Grid.Row verticalAlign="middle">
                  <Grid.Column width={8}>
                    <Header size="huge" icon>
                      <p>
                        See the quiz master to <br />
                        register your team
                      </p>
                      <p>
                        You'll need at least <br />
                        one phone or tablet <br />
                        per team to play
                      </p>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header size="huge" icon>
                      <QRCode value="https://quiz.inclose.com.au" />
                      <p>quiz.inclose.com.au</p>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </div>
        )}

        {event.progress.phase === 'open' &&
          event.progress.question > 0 && (
            <Progress
              value={event.progress.question}
              total={round.questions.size}
              color="blue"
              size="tiny"
              style={{ margin: 0 }}
            />
          )}
        {event.progress.phase === 'reviewing' &&
          event.progress.reviewing > 0 && (
            <Progress
              value={event.progress.reviewing}
              total={round.questions.size}
              color="blue"
              size="tiny"
              style={{ margin: 0 }}
            />
          )}
        {event.progress.phase === 'open' &&
          event.progress.question > 0 &&
          questionID && (
            <Question
              id={questionID}
              number={event.progress.question}
              question={this.props.stores.quiz.questions.get(questionID)}
              inputDisabled
              presenting
            />
          )}

        {event.progress.phase === 'reviewing' &&
          event.progress.reviewing > 0 &&
          questionID && (
            <Answer
              id={questionID}
              number={event.progress.reviewing}
              question={this.props.stores.quiz.questions.get(questionID)}
              presenting
            />
          )}

        {event.progress.phase === 'finalised' &&
          event.progress.released >= event.progress.round && (
            <div>
              <Header textAlign="center">
                Round {event.progress.round} Scores
              </Header>
              <ScoresPanel
                scores={scores}
                round={event.progress.round}
                presenting
              />
            </div>
          )}
      </div>
    );
  }
}

export default inject('stores')(observer(PresentationScreen));
