import React from 'react';
import { Form } from 'semantic-ui-react';

export default function TrueFalseAnswerField(props) {
  return (
    <Form.Group inline>
      <label>Answer</label>
      <Form.Radio
        label="True"
        value="true"
        checked={props.answer === true}
        onChange={props.changeAnswer}
      />
      <Form.Radio
        label="False"
        value="false"
        checked={props.answer === false}
        onChange={props.changeAnswer}
      />
    </Form.Group>
  );
}
