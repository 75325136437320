import React, { Component } from 'react';
import { Menu, Label, Icon, Grid, Progress } from 'semantic-ui-react';

class StatusFooter extends Component {
  statusStyles = {
    green: {
      backgroundColor: '#e5f9e7',
      color: 'rgb(30, 188, 48)',
      borderTop: 'rgba(30, 188, 48, 0.15) 1px solid'
    },
    yellow: {
      backgroundColor: '#fff8db',
      color: 'rgb(181, 129, 5)',
      borderTop: 'rgba(181, 129, 5, 0.15) 1px solid'
    },
    orange: {
      backgroundColor: '#ffedde',
      color: 'rgb(242, 113, 28)',
      borderTop: 'rgba(242, 113, 28, 0.15) 1px solid'
    },
    red: {
      backgroundColor: '#ffe8e6',
      color: 'rgb(219, 40, 40)',
      borderTop: 'rgba(219, 40, 40, 0.15) 1px solid'
    }
  };

  render() {
    const { status } = this.props;
    let content = null;
    let style = this.props.error ? this.statusStyles.red : {};

    if (this.props.error) {
      content = (
        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Label color="red">
            <Icon name="warning sign" />
            {this.props.error}
          </Label>
        </div>
      );
    } else if (status === 'Paused') {
      return null;
    } else {
      const { round, questions, totalQuestions, answers, score } = this.props;
      if (status === 'Open' && answers !== questions) {
        style = this.statusStyles.orange;
      }
      content = (
        <Grid
          columns="equal"
          textAlign="center"
          style={{
            width: '100%',
            marginLeft: 0,
            marginRight: 0,
            fontSize: '1.1rem'
          }}
        >
          {status === 'Open' &&
            questions > 0 && (
              <Progress
                value={questions}
                total={totalQuestions}
                color="orange"
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  fontSize: '0.15em',
                  marginBottom: 0
                }}
              />
            )}
          {status === 'Open' &&
            answers > 0 && (
              <Progress
                value={answers}
                total={totalQuestions}
                color="blue"
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  fontSize: '0.15em',
                  marginBottom: 0,
                  background: 0
                }}
              />
            )}

          <Grid.Column>
            <div>
              <span style={{ fontWeight: 700 }}>Round {round}</span>
              <span style={{ marginLeft: '0.75rem' }}>{status}</span>
            </div>
          </Grid.Column>
          {score !== undefined &&
            score !== null && (
              <Grid.Column>
                <div>
                  <span style={{ fontWeight: 700 }}>Score</span>
                  <span style={{ marginLeft: '0.75rem' }}>
                    {score.score} / {score.roundMax}
                  </span>
                </div>
              </Grid.Column>
            )}
          {answers !== undefined &&
            answers !== null && (
              <Grid.Column>
                <div>
                  <span style={{ fontWeight: 700 }}>Answered</span>
                  <span style={{ marginLeft: '0.75rem' }}>
                    {answers} / {questions}
                  </span>
                </div>
              </Grid.Column>
            )}
        </Grid>
      );
    }

    return (
      <Menu
        borderless
        fixed="bottom"
        style={{
          paddingTop: '0.5em',
          ...style
        }}
      >
        {content}
      </Menu>
    );
  }
}

export default StatusFooter;
