import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Divider, Segment, Header, Image } from 'semantic-ui-react';

import TrueFalseQuestion from './questions/truefalse.question.js';
import MultipleChoiceQuestion from './questions/multiple.question.js';
import TextQuestion from './questions/text.question.js';

class Question extends Component {
  store = this.props.stores.teams;

  render() {
    const {
      number,
      current,
      id,
      question,
      inputDisabled,
      preload,
      presenting
    } = this.props;

    return (
      <Segment
        disabled={question === null}
        id={current ? 'currentQuestion' : null}
        style={{ marginBottom: '3rem' }}
      >
        <Header size={presenting ? 'large' : 'medium'} textAlign="center">
          Question {number}
        </Header>
        <Divider />
        {preload && <Image src={preload} style={{ display: 'none' }} />}
        {question && question.type === 'truefalse' && (
          <TrueFalseQuestion
            id={id}
            question={question}
            inputDisabled={inputDisabled}
            presenting={presenting}
          />
        )}
        {question && question.type === 'multiple' && (
          <MultipleChoiceQuestion
            id={id}
            question={question}
            inputDisabled={inputDisabled}
            presenting={presenting}
          />
        )}
        {question && question.type === 'text' && (
          <TextQuestion
            id={id}
            question={question}
            inputDisabled={inputDisabled}
            presenting={presenting}
            draft={this.store.getDraftAnswer(id)}
          />
        )}
        {this.props.children}
      </Segment>
    );
  }
}

export default inject('stores')(observer(Question));
