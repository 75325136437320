import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Icon, Form, Modal, Button, Tab } from 'semantic-ui-react';

import isURL from 'validator/lib/isURL';

import ImageSelect from './image.select';

class QuestionImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, activeIndex: 0, uploading: false };
  }

  open = (e, data) =>
    this.setState({
      open: true,
      link: this.props.url ? this.props.url : ''
    });

  close = (e, data) => {
    if (data.action === 'save') {
      if (this.state.activeIndex === 0 && this.state.file) {
        this.setState({ uploading: true });
        this.props.stores.files.uploadImage(
          this.state.file,
          snapshot => {
            // progress snapshot
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
          },
          url => {
            // file uploaded successfully success
            this.setState({ open: false });
            this.props.changeImage(url);
          },
          error => {
            //XXX do something to show error
          }
        );
      } else if (this.state.activeIndex === 1 && this.state.link) {
        this.setState({ open: false });
        this.props.changeImage(this.state.link);
      }
    } else {
      this.setState({ open: false });
    }
  };

  tabChanged = (e, data) => this.setState({ activeIndex: data.activeIndex });
  linkChanged = (e, { value }) => this.setState({ link: value });
  fileChanged = value => this.setState({ file: value });

  render() {
    const { open } = this.state;
    const { triggerIcon, triggerText } = this.props;

    const panes = [
      {
        menuItem: 'Upload',
        render: () => (
          <Tab.Pane style={{ minHeight: '70px' }}>
            <ImageSelect changeFile={this.fileChanged} file={this.state.file} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Link',
        render: () => (
          <Tab.Pane style={{ minHeight: '70px' }}>
            <Form>
              <Form.Input
                // label="Link"
                placeholder="URL"
                type="url"
                value={this.state.link}
                onChange={this.linkChanged}
              />
            </Form>
          </Tab.Pane>
        )
      }
    ];

    const linkValid = this.state.link ? isURL(this.state.link) : false;
    const saveButtonEnabled =
      this.state.activeIndex === 0
        ? this.state.file && !this.state.uploading
        : linkValid;
    const saveButtonIcon =
      this.state.activeIndex === 0 ? 'upload' : 'checkmark';
    const saveButtonText = this.state.activeIndex === 0 ? 'Upload' : 'Add';

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button icon={!!triggerIcon}>
              {triggerIcon && <Icon name={triggerIcon} />} {triggerText}
            </Button>
          )
        }
        open={open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick={false}
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>Image</Modal.Header>
        <Modal.Content>
          <Tab
            menu={{
              fluid: true,
              vertical: false,
              tabular: false,
              secondary: true,
              pointing: true
            }}
            panes={panes}
            activeIndex={this.state.activeIndex}
            onTabChange={this.tabChanged}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close}>Cancel</Button>
          <Button
            positive
            icon={saveButtonIcon}
            content={saveButtonText}
            onClick={this.close}
            action="save"
            loading={this.state.uploading}
            disabled={!saveButtonEnabled}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(QuestionImageModal);
