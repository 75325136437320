import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Header, Responsive } from 'semantic-ui-react';

import TextBanner from './text.banner';
import ImageCard from './image.card';

import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

class Advert extends Component {
  shuffle(array) {
    var arr = array.slice();
    var currentIndex = arr.length;
    var temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = arr[currentIndex];
      arr[currentIndex] = arr[randomIndex];
      arr[randomIndex] = temporaryValue;
    }

    return arr;
  }

  render() {
    const { compact } = this.props;
    const event = this.props.stores.events.activeEvent;

    if (!event || !event.sponsors) return null;

    const sponsors = Array.from(
      this.props.stores.sponsor.sponsors.values()
    ).filter(s => {
      if (!event.sponsors.includes(s.id)) return false;
      if (!compact && !s.image) return false;
      return true;
    });

    if (sponsors.length === 0) return null;

    //XXX randomise order of sponsors
    var sponsorSlides = [];
    this.shuffle(sponsors).forEach((sponsor, index) => {
      const details = compact ? (
        <TextBanner sponsor={sponsor} />
      ) : (
        <ImageCard sponsor={sponsor} />
      );
      const detailsDiv = <div style={{ padding: 10 }}>{details}</div>;

      sponsorSlides.push(
        <Slide index={index} key={sponsor.id}>
          {sponsor.url && (
            <a href={sponsor.url} target="_blank" rel="noopener noreferrer">
              {detailsDiv}
            </a>
          )}
          {!sponsor.url && detailsDiv}
        </Slide>
      );
    });

    //XXX responsive wrappers are a hack to deal with carousel height being a ratio
    return (
      <div style={{ textAlign: 'center' }}>
        {this.props.message && <Header>{this.props.message}</Header>}
        {compact && (
          <div>
            <Responsive {...Responsive.onlyMobile}>
              <CarouselProvider
                naturalSlideWidth={1}
                naturalSlideHeight={compact ? 0.15 : 1.5}
                totalSlides={sponsorSlides.length}
                isPlaying={true}
                interval={10000}
                touchEnabled={false}
                dragEnabled={false}
                style={{ width: '100%', margin: 'auto' }}
              >
                <Slider>{sponsorSlides}</Slider>
              </CarouselProvider>
            </Responsive>
            <Responsive {...Responsive.onlyTablet}>
              <CarouselProvider
                naturalSlideWidth={1}
                naturalSlideHeight={compact ? 0.1 : 1.5}
                totalSlides={sponsorSlides.length}
                isPlaying={true}
                interval={10000}
                touchEnabled={false}
                dragEnabled={false}
                style={{ width: '100%', margin: 'auto', fontSize: '1.5rem' }}
              >
                <Slider>{sponsorSlides}</Slider>
              </CarouselProvider>
            </Responsive>
            <Responsive {...Responsive.onlyComputer}>
              <CarouselProvider
                naturalSlideWidth={1}
                naturalSlideHeight={compact ? 0.1 : 1.5}
                totalSlides={sponsorSlides.length}
                isPlaying={true}
                interval={10000}
                touchEnabled={false}
                dragEnabled={false}
                style={{ width: '100%', margin: 'auto', fontSize: '1.7rem' }}
              >
                <Slider>{sponsorSlides}</Slider>
              </CarouselProvider>
            </Responsive>
          </div>
        )}
        {!compact && (
          <CarouselProvider
            naturalSlideWidth={1}
            naturalSlideHeight={compact ? 0.2 : 1.5}
            totalSlides={sponsorSlides.length}
            isPlaying={true}
            interval={10000}
            touchEnabled={false}
            dragEnabled={false}
            style={{ width: '100%', margin: 'auto' }}
          >
            <Slider>{sponsorSlides}</Slider>
          </CarouselProvider>
        )}
      </div>
    );
  }
}

export default inject('stores')(observer(Advert));
