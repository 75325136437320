import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import {
  Icon,
  Modal,
  Button,
  Dimmer,
  Loader,
  Header,
  Divider,
  Form,
  Accordion
} from 'semantic-ui-react';

import QuestionItem from '../../components/admin/question.item';

//XXX add filter options
//      - reference provided
//      - age of question (createdAt/updatedAt fields)
//      - text search
//XXX create new question(s) from nested model?
class AddQuestionModal extends Component {
  ui = observable({
    open: false,
    typeFilter: null,
    filterDuplicateQuestions: true,
    filterNoImageQuestions: false,
    selectedQuestions: []
  });

  data = observable({
    loading: false,
    questions: null
  });

  open = () => {
    this.ui.open = true;
    this.data.loading = true;
    this.props.stores.quiz.getQuestions(results => {
      this.data.questions = results;
      this.data.loading = false;
    });
  };

  close = (e, data) => {
    if (data.action === 'add') {
      this.props.stores.quiz.addQuestionsToRound(
        this.ui.selectedQuestions,
        this.props.round
      );
    }
    this.ui.open = false;
    this.data.questions = null;
    this.ui.selectedQuestions = [];
  };

  handleChange = (e, { value }) => {
    this.ui.typeFilter = value;
    console.log(this.ui.typeFilter);
  };

  toggle = (e, data) => {
    if (data.name && this.ui[data.name] !== undefined) {
      this.ui[data.name] = !this.ui[data.name];
    }
  };

  select = (e, data) => {
    e.stopPropagation();
    if (data.checked && !this.ui.selectedQuestions.includes(data.id)) {
      this.ui.selectedQuestions.push(data.id);
    }
    if (!data.checked) {
      this.ui.selectedQuestions.remove(data.id);
    }
    console.log((data.checked ? 'selected' : 'unselected') + ' ' + data.id);
  };

  render() {
    const { quiz, roundNumber, triggerIcon, triggerText } = this.props;

    let questions = [];
    let quizQuestions = this.props.stores.quiz.quizQuestions(quiz.id);
    if (quizQuestions) quizQuestions = quizQuestions.map(q => q.id);

    if (this.data.questions) {
      for (let q of Array.from(this.data.questions.values())) {
        let allow = true;
        if (this.ui.filterDuplicateQuestions && quizQuestions.includes(q.id))
          allow = false;
        if (this.ui.typeFilter && this.ui.typeFilter !== q.type) allow = false;
        if (this.ui.filterNoImageQuestions && !q.image) allow = false;
        if (allow) {
          questions.push(
            <QuestionItem
              key={q.id}
              question={q}
              select={this.select}
              selected={this.ui.selectedQuestions.includes(q.id)}
            />
          );
        }
      }
    }

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button icon={!!triggerIcon}>
              {triggerIcon && <Icon name={triggerIcon} />} {triggerText}
            </Button>
          )
        }
        open={this.ui.open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>Add questions to round {roundNumber}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Dropdown
                clearable
                options={[
                  {
                    key: 1,
                    text: 'Text questions',
                    value: 'text'
                  },
                  {
                    key: 2,
                    text: 'True/false questions',
                    value: 'truefalse'
                  },
                  {
                    key: 3,
                    text: 'Multiple choice questions',
                    value: 'multiple'
                  }
                ]}
                selection
                placeholder="Show all types"
                value={this.ui.typeFilter}
                onChange={this.handleChange}
              />
              <Form.Checkbox
                name="filterNoImageQuestions"
                label="Show image questions only"
                onChange={this.toggle}
                checked={this.ui.filterNoImageQuestions}
              />
              <Form.Checkbox
                name="filterDuplicateQuestions"
                label="Hide questions already used"
                onChange={this.toggle}
                checked={this.ui.filterDuplicateQuestions}
                // disabled
              />
            </Form.Group>
          </Form>
          <Header size="small">Showing {questions.length} questions...</Header>
        </Modal.Content>
        <Divider fitted />
        <Modal.Content scrolling>
          <Modal.Description>
            {this.data.loading ? (
              <Dimmer active inverted>
                <Loader inverted>Loading questions</Loader>
              </Dimmer>
            ) : (
              <Accordion fluid styled style={{ marginBottom: '1.5em' }}>
                {questions}
              </Accordion>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.close}
            action="add"
            disabled={this.ui.selectedQuestions.length === 0}
          >
            {this.ui.selectedQuestions.length === 0
              ? 'Add'
              : this.ui.selectedQuestions.length === 1
              ? 'Add question'
              : 'Add ' + this.ui.selectedQuestions.length + ' questions'}
          </Button>
          <Button onClick={this.close}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(observer(AddQuestionModal));
