import React, { Component } from 'react';
import { inject } from 'mobx-react';
import {
  Header,
  Icon,
  Modal,
  Button,
  Divider,
  Image,
  Segment
} from 'semantic-ui-react';

class RemoveQuestionModal extends Component {
  state = { open: false };

  open = () => this.setState({ open: true });
  close = (e, data) => {
    this.setState({ open: false });
    if (
      data.action &&
      data.action === 'remove' &&
      data.round &&
      data.question
    ) {
      this.props.stores.quiz.removeQuestionFromRound(data.question, data.round);
    }
    // if (data.action && data.action === 'delete' && data.question) {
    //   alert('Delete question  ' + data.question.id);
    // }
  };

  render() {
    const { open } = this.state;
    const {
      round,
      roundNumber,
      question,
      questionNumber,
      triggerIcon,
      triggerText
    } = this.props;

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button icon={!!triggerIcon}>
              {triggerIcon && <Icon name={triggerIcon} />} {triggerText}
            </Button>
          )
        }
        open={open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>
          Remove question {questionNumber} from round {roundNumber}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Segment textAlign="center">
              <h3>{question.question}</h3>
              {question.image && (
                <Image src={question.image} size="small" inline />
              )}
            </Segment>
          </Modal.Description>
          <Divider />
          <Modal.Description>
            <p>
              Question {questionNumber} will removed from this round but can be
              added again later.
            </p>
            <p>
              <Button
                color="orange"
                onClick={this.close}
                action="remove"
                round={round}
                question={question}
              >
                <Icon name="trash alternate outline" /> Remove
              </Button>
            </p>
          </Modal.Description>
          <Divider />
          <Modal.Description>
            <Header>Delete the question?</Header>
            <p>
              <Icon name="warning sign" color="red" />
              Warning: The question will be deleted and cannot be recovered. The
              question will be removed from all other quizzes.
            </p>
            <p>
              <Button color="red" onClick={this.close} action="delete" disabled>
                <Icon name="delete" /> Delete
              </Button>
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(RemoveQuestionModal);
