import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Segment,
  Header,
  Divider,
  List,
  Loader,
  Container,
  Grid,
  Button
} from 'semantic-ui-react';

import VerifyAnswer from './verify.answer.js';

class VerifyAnswersPanel extends Component {
  store = this.props.stores.teams;

  answerString = q => {
    if (!q) return null;
    if (q.type === 'truefalse') {
      return q.answer ? 'True' : 'False';
    } else if (q.type === 'multiple') {
      return q.options.get(q.answer);
    }
    return q.answer.toString();
  };

  // returns...
  //    true: answer is definitely correct
  //    false: answer is definitely incorrect
  //    null: answer cannot be evaluated automatically
  isAnswerCorrect(q, a) {
    if (!a || !q) return null;
    if (q.type === 'text') {
      if (
        q.answer
          .toString()
          .replace(/\s+/g, '')
          .toLowerCase() ===
        a.answer
          .toString()
          .replace(/\s+/g, '')
          .toLowerCase()
      ) {
        return true;
      }
      return null;
    }
    return a.answer === q.answer;
  }

  handleClear = (e, data) => {
    const { round } = this.props;
    const teams = this.store.teams;
    const questions = Array.from(round.questions.values());

    let teamAnswers = new Map();
    if (teams && questions) {
      teams.forEach(team => {
        let answers = new Map();
        questions.forEach(qid => {
          if (team.answers.get(qid)) {
            answers.set(qid, null);
          }
        });
        teamAnswers.set(team.id, answers);
      });
    }
    this.store.verifyAnswers(teamAnswers);
  };

  handleAutoVerify = (e, data) => {
    const { round } = this.props;
    const teams = this.store.teams;
    const questions = Array.from(round.questions.values());

    let teamAnswers = new Map();
    if (teams && questions) {
      teams.forEach(team => {
        let answers = new Map();
        questions.forEach(qid => {
          const answer = team.answers.get(qid);
          if (answer) {
            const isYetToBeVerified =
              answer.correct === undefined ||
              answer.correct === null ||
              answer.verifiedAt < answer.timestamp;

            if (isYetToBeVerified) {
              const question = this.props.stores.quiz.questions.get(qid);
              answers.set(qid, this.isAnswerCorrect(question, answer));
            }
          }
        });
        teamAnswers.set(team.id, answers);
      });
    }
    this.store.verifyAnswers(teamAnswers);
  };

  // returns true if there are answers that are yet to be verified
  hasUnverifiedAnswers() {
    let unverified = false;

    const { round } = this.props;
    const teams = this.store.teams;
    const questions = Array.from(round.questions.values());

    if (teams && questions) {
      teams.forEach(team => {
        questions.forEach(qid => {
          const answer = team.answers.get(qid);
          if (answer) {
            if (
              answer.correct === undefined ||
              answer.correct === null ||
              answer.verifiedAt < answer.timestamp
            ) {
              unverified = true;
            }
          }
        });
      });
    }

    return unverified;
  }

  render() {
    const { round, roundNumber, transitionRound } = this.props;
    const teams = this.store.teams;

    return (
      <div>
        <Segment>
          <Container text textAlign="center">
            <Button primary onClick={this.handleAutoVerify}>
              Auto verify
            </Button>
            <Button secondary onClick={this.handleClear}>
              Clear
            </Button>
          </Container>
          <Divider />
          {round &&
            Array.from(round.questions.entries()).map(e => {
              const number = e[0];
              const qid = e[1];
              const q = this.props.stores.quiz.questions.get(qid);
              return (
                <div key={'Q' + number}>
                  <Header>
                    <Header.Subheader>
                      Q{number}. {q && q.question}
                    </Header.Subheader>
                    <Header.Content>A. {this.answerString(q)}</Header.Content>
                  </Header>
                  {(!teams ||
                    !q ||
                    q.answer === undefined ||
                    q.answer === null) && <Loader active inline />}
                  {teams && q && q.answer !== undefined && q.answer !== null && (
                    <List divided verticalAlign="middle" selection>
                      {teams.map(t => {
                        const answers = t.answers;
                        const a = answers ? answers.get(qid) : null;
                        return (
                          <VerifyAnswer
                            number={number}
                            question={q}
                            answer={a}
                            teamID={t.id}
                            key={'Q' + number + '.' + t.id}
                          />
                        );
                      })}
                    </List>
                  )}
                  <Divider />
                </div>
              );
            })}
        </Segment>
        {transitionRound && (
          <Grid stackable columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Button
                  fluid
                  primary
                  onClick={transitionRound}
                  transitionto="reviewing"
                  disabled={this.hasUnverifiedAnswers()}
                >
                  Review round {roundNumber}
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  fluid
                  secondary
                  onClick={transitionRound}
                  transitionto="finalised"
                  disabled={this.hasUnverifiedAnswers()}
                >
                  Finalise round {roundNumber}&nbsp;&nbsp;(skip review)
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}

export default inject('stores')(observer(VerifyAnswersPanel));
