import React, { Component } from 'react';
import { Segment, Responsive, Header, Divider, Image } from 'semantic-ui-react';

class ImageCard extends Component {
  render() {
    const { sponsor } = this.props;

    return (
      <Segment>
        <Responsive maxWidth={767}>
          <Header size="large">
            <Header.Content>
              {sponsor.name}
              <Header.Subheader>{sponsor.tagline}</Header.Subheader>
            </Header.Content>
          </Header>
        </Responsive>
        <Responsive minWidth={768}>
          <Header size="huge">
            <Header.Content>
              {sponsor.name}
              <Header.Subheader>{sponsor.tagline}</Header.Subheader>
            </Header.Content>
          </Header>
        </Responsive>
        <Divider />
        <Responsive maxWidth={767}>
          <Image src={sponsor.image} centered size="medium" />
        </Responsive>
        <Responsive minWidth={768}>
          <Image src={sponsor.image} centered size="large" />
        </Responsive>
      </Segment>
    );
  }
}

export default ImageCard;
