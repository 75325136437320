import React, { Component } from 'react';
import {
  Accordion,
  Grid,
  Button,
  Icon,
  Image,
  Checkbox
} from 'semantic-ui-react';

import QuestionModal from '../../components/admin/question.modal';
import RemoveQuestionModal from '../../components/admin/remove.question.modal';

export default class QuestionItem extends Component {
  state = { expanded: false };

  handleClick = e => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      question,
      questionNumber,
      numberOfQuestions,
      round,
      roundNumber,
      moveQuestion,
      select,
      selected
    } = this.props;
    if (!question) return null;
    const { expanded } = this.state;

    var iconName = null;
    if (question.type === 'text') iconName = 'terminal';
    if (question.type === 'truefalse') iconName = 'toggle on';
    if (question.type === 'multiple') iconName = 'numbered list';

    return (
      <div style={{ borderBottom: '1px solid rgba(34,36,38,.15)' }}>
        <Accordion.Title active={expanded} onClick={this.handleClick}>
          <Grid>
            <Grid.Row stretched color={selected ? 'teal' : null}>
              <Grid.Column width={2}>
                <span>
                  <Icon name="dropdown" />
                  {questionNumber && <span>{questionNumber}.</span>}
                  {select && (
                    <Checkbox
                      id={question.id}
                      checked={selected}
                      onChange={select}
                    />
                  )}
                </span>
              </Grid.Column>
              <Grid.Column width={11}>{question.question}</Grid.Column>
              <Grid.Column width={3} textAlign="right">
                <span>
                  {question.image && (
                    <Icon
                      name="picture"
                      color="grey"
                      style={{ marginRight: '1em' }}
                    />
                  )}
                  {iconName && <Icon name={iconName} color="grey" />}
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={expanded}>
          <Grid stackable>
            <Grid.Row color={selected ? 'teal' : null}>
              <Grid.Column width={2}>
                {round && (
                  <Button.Group size="mini" basic>
                    <Button
                      icon="arrow up"
                      title="Move question up"
                      disabled={questionNumber <= 1}
                      onClick={e => {
                        e.stopPropagation();
                        moveQuestion(question, round, questionNumber - 1);
                      }}
                    />
                    <Button
                      icon="arrow down"
                      title="Move question down"
                      disabled={questionNumber >= numberOfQuestions}
                      onClick={e => {
                        e.stopPropagation();
                        moveQuestion(question, round, questionNumber + 1);
                      }}
                    />
                  </Button.Group>
                )}
              </Grid.Column>

              {question.image && (
                <Grid.Column width={4} textAlign="right">
                  <Image src={question.image} size="small" />
                </Grid.Column>
              )}
              <Grid.Column width={question.image ? 8 : 12}>
                Answer:
                <br />
                <span style={{ fontWeight: 700 }}>
                  {question.type === 'text' && question.answer}
                  {question.type === 'multiple' &&
                    question.options.get(question.answer)}
                  {question.type === 'truefalse' && question.answer && 'True'}
                  {question.type === 'truefalse' && !question.answer && 'False'}
                </span>
              </Grid.Column>
              <Grid.Column width={2}>
                <Button.Group size="mini" basic>
                  <QuestionModal
                    round={round}
                    roundNumber={roundNumber}
                    question={question}
                    triggerIcon="edit outline"
                  >
                    <Button icon>
                      <Icon name="edit outline" />
                    </Button>
                  </QuestionModal>
                  <RemoveQuestionModal
                    round={round}
                    roundNumber={roundNumber}
                    question={question}
                    questionNumber={questionNumber}
                    triggerIcon="trash alternate outline"
                  >
                    <Button icon>
                      <Icon name="trash alternate outline" />
                    </Button>
                  </RemoveQuestionModal>
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            {question && (
              <div style={{ width: '100%' }}>
                <p style={{ fontSize: '0.6rem', textAlign: 'right' }}>
                  {question.id}
                </p>
              </div>
            )}
          </Grid>
        </Accordion.Content>
      </div>
    );
  }
}
