import { reaction } from 'mobx';

import './config';
import network from './network.store';
import auth from './auth.store';
import teams from './teams.store';
import quiz from './quiz.store';
import events from './events.store';
import sponsor from './sponsor.store';
import files from './files.store';

network.initialise();

reaction(
  () => auth.user,
  (user, reaction) => {
    if (auth.user) {
      //   console.log('User updated: ' + auth.user.uid);
      teams.subscribe(auth.user);
      // quiz.subscribe(auth.user);
      events.subscribe(auth.user, quiz, teams, sponsor);
    } else {
      //   console.log('User logged out');
      teams.unsubscribe();
      quiz.unsubscribe();
      events.unsubscribe();
    }
  }
);

export default { network, auth, teams, quiz, events, sponsor, files };
