import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Menu, Dropdown, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import screenfull from 'screenfull';

class MainMenu extends Component {
  constructor() {
    super();
    this.state = {
      isFullscreen: screenfull.enabled ? screenfull.isFullscreen : false
    };

    if (screenfull.enabled) {
      screenfull.on('change', () => {
        console.log('Am I fullscreen?', screenfull.isFullscreen ? 'Yes' : 'No');
        this.setState({ isFullscreen: screenfull.isFullscreen });
      });
    }
  }

  toggleFullscreen = () => {
    if (screenfull.enabled) screenfull.toggle();
  };

  handleSignOut = () => {
    if (this.props.stores.auth.user) {
      if (this.props.stores.auth.isAnonymous) {
        this.props.stores.teams.removeUserFromTeam();
      } else {
        this.props.stores.auth.signOut();
      }
    }
  };

  render() {
    const { event, team } = this.props;

    const fullscreenMenuItem = screenfull.enabled ? (
      <Dropdown.Item
        icon={this.state.isFullscreen ? 'compress' : 'expand'}
        text={this.state.isFullscreen ? 'Exit fullscreen' : 'Fullscreen'}
        onClick={this.toggleFullscreen}
      />
    ) : null;

    return (
      <Menu borderless fixed="top">
        <Menu.Item as={Link} to="/" style={{ maxWidth: '80%' }}>
          <Header as="h3">
            <Icon name="graduation" />
            <Header.Content>
              {event ? event.name : 'Quiz App'}
              {team && <Header.Subheader>{team.name}</Header.Subheader>}
            </Header.Content>
          </Header>
        </Menu.Item>
        {team && (
          <Menu.Menu position="right">
            <Dropdown item icon="bars">
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to="/quiz"
                  icon="graduation"
                  text="Quiz"
                />
                <Dropdown.Item
                  as={Link}
                  to="/scorecard"
                  icon="trophy"
                  text="Scorecard"
                />
                <Dropdown.Item
                  as={Link}
                  to="/sponsors"
                  icon="gift"
                  text="Sponsors"
                />
                <Dropdown.Divider />
                <Dropdown.Item
                  as={Link}
                  to="/passcode"
                  icon="user secret"
                  text="Passcode"
                />
                {fullscreenMenuItem}
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={this.handleSignOut}
                  icon="sign out"
                  text="Sign out"
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        )}
        {!team && (
          <Menu.Menu position="right">
            <Dropdown item icon="bars">
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to="/present"
                  icon="tv"
                  text="Presentation mode"
                />
                {fullscreenMenuItem}
                <Dropdown.Divider />
                <Dropdown.Item
                  as={Link}
                  to="/"
                  icon="users"
                  text="Join a team"
                />
                <Dropdown.Item
                  as={Link}
                  to="/login"
                  icon="sign in"
                  text="Login as Quiz Master"
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        )}
      </Menu>
    );
  }
}

export default inject('stores')(observer(MainMenu));
