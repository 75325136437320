import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Form, Input, Label, Button } from 'semantic-ui-react';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = { teamName: '', error: null, passcode: '' };
    this.store = this.props.stores.teams;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    if (name === 'teamName') {
      if (value.trim() === '') {
        this.setState({ error: 'Team name cannot be empty' });
      } else if (this.store.teamExists(value)) {
        this.setState({ error: 'Team name already exists' });
      } else {
        this.setState({ error: null });
      }
    }
  };

  handleSubmit = () => {
    if (!this.state.error) {
      this.store.createTeam(
        this.state.teamName,
        this.props.user,
        this.state.passcode
      );
      if (this.props.submitted) {
        this.props.submitted();
      }
      this.setState({ teamName: '', error: null, passcode: '' });
    }
  };

  randomisePasscode = e => {
    this.setState({
      passcode: (Math.floor(Math.random() * (10000 - 1000)) + 1000).toString()
    });
  };

  render() {
    const { teamName, passcode } = this.state;
    const { showPasscode } = this.props;

    return (
      <Form>
        <Form.Group inline={showPasscode}>
          <Form.Field width={showPasscode ? 10 : 16} required>
            <label>Team name</label>
            <Input
              placeholder="Enter team name"
              icon="users"
              iconPosition="left"
              name="teamName"
              value={teamName}
              size="large"
              onChange={this.handleChange}
              error={!!this.state.error}
            />
            {this.state.error && (
              <Label basic color="red" pointing>
                {this.state.error}
              </Label>
            )}
          </Form.Field>
          {showPasscode && (
            <Form.Field width={5}>
              <label>Passcode</label>
              <Input
                placeholder="Enter passcode"
                icon="user secret"
                iconPosition="left"
                name="passcode"
                value={passcode}
                size="large"
                onChange={this.handleChange}
              />
            </Form.Field>
          )}
          {showPasscode && (
            <Form.Field width={1}>
              <Button icon="random" onClick={this.randomisePasscode} />
            </Form.Field>
          )}
        </Form.Group>
        <Form.Button
          fluid
          primary
          content="Create team"
          disabled={!!this.state.error || !this.state.teamName}
          style={{ marginTop: '2rem' }}
          onClick={this.handleSubmit}
        />
      </Form>
    );
  }
}

export default inject('stores')(observer(RegisterForm));
