import { observable, action } from 'mobx';
import firebase from 'firebase';

var sponsorStore = observable({
  loading: true,
  sponsorDB: firebase.firestore().collection('sponsors'),
  sponsorRefs: new Map(),
  sponsors: observable(new Map()),

  subscribe(sponsorID) {
    if (!this.sponsorRefs.has(sponsorID)) {
      console.log('Subscribing to sponsor ' + sponsorID);
      this.sponsorRefs.set(
        sponsorID,
        this.sponsorDB.doc(sponsorID).onSnapshot(
          function (doc) {
            this.loading = false;
            const id = doc.ref.id;
            const data = doc.data();
            // console.log(id);
            // console.log(data);
            if (data) {
              // console.log('Updating data for sponsor: ' + id);
              data.id = id;
              this.sponsors.set(id, data);
            } else {
              console.log('Sponsor ' + id + ' removed');
              this.sponsors.delete(id);
            }
          }.bind(this)
        )
      );
    }
  },

  unsubscribe() {
    this.loading = true;
    this.sponsors.clear();
    this.sponsorRefs.clear();
    if (this.closeDBSession) {
      this.closeDBSession();
    }
  },

  unsubscribeToSponsor(sponsorID) {
    // console.log('Unsubscribing to sponsor ' + sponsorID);
    const ref = this.sponsorRefs.get(sponsorID);
    if (ref) ref();
    this.sponsorRefs.delete(sponsorID);
    this.sponsors.delete(sponsorID);
  },

  sponsor(sponsorID) {
    const sponsor = this.sponsors.get(sponsorID);
    return sponsor;
  },

  sponsorsFromList(sponsorIDs) {
    let sponsorsList = sponsorIDs.map(id => this.sponsors.get(id));
    return sponsorsList;
  },

  createSponsor: action(function (sponsor) {
    if (sponsor) {
      //XXX need more checks to validate the sponsor
      const sponsorRef = this.sponsorDB.doc();

      sponsor['createdAt'] = firebase.firestore.FieldValue.serverTimestamp();
      sponsorRef
        .set(sponsor)
        .then(function () {
          console.log('Sponsor document successfully written!');
        })
        .catch(function (error) {
          console.error('Error writing sponsor document: ', error);
        });
    }
  }),

  updateSponsor: action(function (id, sponsor) {
    var sponsorRef = this.questionsDB.doc(id);

    //XXX need more checks to validate the sponsor
    if (sponsorRef) {
      sponsor['updatedAt'] = firebase.firestore.FieldValue.serverTimestamp();
      sponsorRef
        .set(sponsor, { merge: true })
        .then(function () {
          console.log('Sponsor document successfully written!');
        })
        .catch(function (error) {
          console.error('Error writing sponsor document: ', error);
        });
    }
  })
});

export default sponsorStore;
