import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Divider, Segment, Header } from 'semantic-ui-react';

import TrueFalseAnswer from './answers/truefalse.answer.js';
import MultipleChoiceAnswer from './answers/multiple.answer.js';
import TextAnswer from './answers/text.answer.js';

class Answer extends Component {
  render() {
    const { number, id, question, correct, presenting } = this.props;

    return (
      <Segment disabled={question === null} style={{ marginBottom: '3rem' }}>
        <Header size={presenting ? 'large' : 'medium'} textAlign="center">
          Answer {number}
        </Header>
        <Divider />
        {question &&
          question.type === 'truefalse' && (
            <TrueFalseAnswer
              id={id}
              question={question}
              correct={correct}
              presenting={presenting}
            />
          )}
        {question &&
          question.type === 'multiple' && (
            <MultipleChoiceAnswer
              id={id}
              question={question}
              correct={correct}
              presenting={presenting}
            />
          )}
        {question &&
          question.type === 'text' && (
            <TextAnswer
              id={id}
              question={question}
              correct={correct}
              presenting={presenting}
            />
          )}
      </Segment>
    );
  }
}

export default observer(Answer);
