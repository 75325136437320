import React, { Component } from 'react';

import { Accordion, Icon } from 'semantic-ui-react';
import ReferenceInput from './reference.input';

class ReferencesField extends Component {
  state = { open: false };

  handleClick = (e, titleProps) => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { label, reference, changeReference } = this.props;

    return (
      <Accordion>
        <Accordion.Title active={this.state.open} onClick={this.handleClick}>
          <Icon name="dropdown" />
          {label ? label : 'References'}
        </Accordion.Title>
        <Accordion.Content active={this.state.open}>
          <ReferenceInput
            reference={reference}
            changeReference={changeReference}
          />
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default ReferencesField;
