import { observable, action } from 'mobx';
import firebase from 'firebase';

var teamsStore = observable({
  loading: true,
  teamMap: observable(new Map()),
  user: null,
  activeTeam: null,
  draftAnswers: observable(new Map()),

  db: firebase.firestore().collection('teams'),
  closeDBSession: null,

  subscribe(user) {
    if (this.closeDBSession) this.unsubscribe();
    this.user = user;
    this.closeDBSession = this.db.onSnapshot(
      function(snapshot) {
        this.loading = false;
        if (snapshot.size) {
          snapshot.docChanges().forEach(
            function(change) {
              const id = change.doc.ref.id;
              const data = change.doc.data();
              data.id = id;
              // console.log(change.type + ': ' + id);
              // console.log(data);
              if (change.type === 'added' || change.type === 'modified') {
                var answers = new Map();
                if (data.answers) {
                  Object.getOwnPropertyNames(data.answers).forEach(qid => {
                    answers.set(qid, data.answers[qid]);
                  });
                }
                data.answers = answers;
                this.teamMap.set(id, data);
              } else if (change.type === 'removed') {
                this.teamMap.delete(id);
              }

              // update active team
              // XXX need to clean out cached answers if team changes
              const teamids = Array.from(this.teamMap.keys());
              const activeTeamID = teamids.find(id => {
                const team = this.teamMap.get(id);
                return team.users && team.users.includes(user.uid);
              });
              this.activeTeam = activeTeamID
                ? this.teamMap.get(activeTeamID)
                : null;
            }.bind(this)
          );
        }
      }.bind(this)
    );
  },

  unsubscribe() {
    this.teamMap.clear();
    this.loading = true;
    this.activeTeam = null;
    if (this.closeDBSession) {
      this.closeDBSession();
    }
  },

  get teams() {
    return Array.from(this.teamMap.values()).sort((a, b) =>
      ('' + a.name).localeCompare(b.name)
    );
  },

  get teamNames() {
    return Array.from(this.teamMap.entries())
      .sort((a, b) => ('' + a[1].name).localeCompare(b[1].name))
      .map(function(t) {
        return { text: t[1].name, value: t[0] };
      });
  },

  getTeam: function(teamID) {
    return this.teamMap.get(teamID);
  },

  teamExists: function(name) {
    return this.teamNames.find(n => n.text === name);
  },

  addUserToTeam: action(function(teamid) {
    var teamRef = this.db.doc(teamid);

    teamRef.update({
      users: firebase.firestore.FieldValue.arrayUnion(this.user.uid)
    });
  }),

  removeUserFromTeam: action(function() {
    if (this.activeTeam) {
      var teamRef = this.db.doc(this.activeTeam.id);

      teamRef.update({
        users: firebase.firestore.FieldValue.arrayRemove(this.user.uid)
      });
    }
  }),

  createTeam: action(function(name, user, passcode) {
    if (!this.teamExists(name)) {
      var details = {
        name: name,
        passcode: passcode
          ? passcode
          : (Math.floor(Math.random() * (10000 - 1000)) + 1000).toString(),
        users: user ? firebase.firestore.FieldValue.arrayUnion(user.uid) : [],
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      this.db
        .add(details)
        .then(function(docRef) {
          console.log('Team created with ID: ', docRef.id);
        })
        .catch(function(error) {
          console.error('Error adding document: ', error);
        });
    }
  }),

  deleteTeam: action(function(id) {
    this.db
      .doc(id)
      .delete()
      .then(function(docRef) {
        console.log('Team deleted with ID: ', id);
      })
      .catch(function(error) {
        console.error('Error deleting document: ', error);
      });
  }),

  setDraftAnswer: action(function(qid, answer) {
    console.log('setting draft answer for ' + qid + ' to ' + answer);
    this.draftAnswers.set(qid, answer);
  }),

  clearDraftAnswer: action(function(qid) {
    this.draftAnswers.delete(qid);
    console.log('cleared draft answer for ' + qid);
  }),

  hasDraftAnswer: function(qid) {
    return this.draftAnswers.has(qid);
  },

  getDraftAnswer: function(qid) {
    return this.draftAnswers.get(qid);
  },

  setAnswer: action(function(qid, answer) {
    console.log('setting answer for ' + qid + ' to ' + answer);
    if (this.activeTeam && answer !== undefined) {
      var teamRef = this.db.doc(this.activeTeam.id);
      var answersUpdate = {};
      answersUpdate[`answers.${qid}.answer`] = answer;
      answersUpdate[
        `answers.${qid}.timestamp`
      ] = firebase.firestore.FieldValue.serverTimestamp();
      teamRef.update(answersUpdate);
    }
  }),

  verifyAnswer: action(function(teamID, qid, correct) {
    var teamRef = this.db.doc(teamID);
    if (teamRef) {
      var answersUpdate = {};
      answersUpdate[`answers.${qid}.correct`] = correct;
      answersUpdate[`answers.${qid}.verifiedAt`] =
        correct !== null
          ? firebase.firestore.FieldValue.serverTimestamp()
          : null;
      teamRef.update(answersUpdate);
    }
  }),

  // teams parameter: map of teams to (map of question IDs to correct flags)
  verifyAnswers: action(function(teams) {
    teams.forEach((answers, teamID) => {
      var teamRef = this.db.doc(teamID);
      if (teamRef) {
        var answersUpdate = {};
        answers.forEach((correct, qid) => {
          answersUpdate[`answers.${qid}.correct`] = correct;
          answersUpdate[`answers.${qid}.verifiedAt`] =
            correct !== null
              ? firebase.firestore.FieldValue.serverTimestamp()
              : null;
        });
        teamRef.update(answersUpdate);
        // console.log(answersUpdate);
      }
    });
  })
});

export default teamsStore;
