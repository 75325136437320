import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';

class QuestionImage extends Component {
  style = {
    marginBottom: '1.5rem',
    minHeight: '120px',
    backgroundImage: 'url(images/icons/image-placeholder.svg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    border: 'rgb(235,235,235) 1px solid'
  };

  render() {
    const { src, presenting } = this.props;

    return (
      <Image
        src={src}
        wrapped
        centered
        size={presenting ? 'big' : 'medium'}
        style={
          presenting
            ? {
                maxHeight: '70vh',
                width: 'auto',
                height: 'auto',
                ...this.style
              }
            : this.uistyle
        }
      />
    );
  }
}

export default QuestionImage;
