import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Loader, Segment, Header, Statistic, Button } from 'semantic-ui-react';

import ScoresPanel from '../../components/admin/scores.panel.js';

class ScoresScreen extends Component {
  releaseScores = (e, value) => {
    this.props.stores.events.releaseScores(value.round);
  };

  render() {
    const event = this.props.stores.events.activeEvent;
    const scores = this.props.stores.events.scores;

    if (!event || !scores) {
      return <Loader />;
    }

    const numberOfRounds =
      event.progress.phase === 'ready' || event.progress.phase === 'open'
        ? event.progress.round - 1
        : event.progress.round;
    const released = event.progress.released;

    return (
      <div>
        <Header textAlign="center">Rounds</Header>
        <Segment>
          <Statistic.Group widths="two" size="tiny">
            <Statistic>
              <Statistic.Value>{numberOfRounds}</Statistic.Value>
              <Statistic.Label>completed</Statistic.Label>
            </Statistic>
            <Statistic color={released < numberOfRounds ? 'orange' : 'black'}>
              <Statistic.Value>{released}</Statistic.Value>
              <Statistic.Label>released</Statistic.Label>
            </Statistic>
          </Statistic.Group>

          {released < numberOfRounds && (
            <Button
              fluid
              primary
              onClick={this.releaseScores}
              round={numberOfRounds}
              disabled={released >= numberOfRounds}
              style={{ marginTop: '1em' }}
            >
              Release scores
            </Button>
          )}
        </Segment>
        <Header textAlign="center">
          Scores
          <Header.Subheader>
            after {numberOfRounds} round{released > 1 ? 's' : ''}
          </Header.Subheader>
        </Header>
        <ScoresPanel scores={scores} />
      </div>
    );
  }
}

export default inject('stores')(observer(ScoresScreen));
