import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Segment } from 'semantic-ui-react';

class AnswerLabel extends Component {
  baseStyle = {
    margin: 'auto',
    padding: '0.7em 1.3em'
  };

  statusStyles = {
    green: {
      backgroundColor: '#e5f9e7',
      borderColor: 'rgb(30, 188, 48)'
    },
    red: {
      backgroundColor: '#ffe8e6',
      borderColor: 'rgb(219, 40, 40)'
    }
  };

  render() {
    const { answer, correct, presenting } = this.props;

    const colors =
      correct === true
        ? this.statusStyles.green
        : correct === false
          ? this.statusStyles.red
          : {};

    return (
      <Segment
        size={presenting ? 'massive' : 'big'}
        style={
          presenting
            ? {
                fontSize: '3rem',
                lineHeight: '3rem',
                ...this.baseStyle,
                ...colors
              }
            : { ...this.baseStyle, ...colors }
        }
        textAlign="left"
        compact
      >
        {answer}
      </Segment>
    );
  }
}

export default observer(AnswerLabel);
