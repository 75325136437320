import React, { Component } from 'react';
import {
  Ref,
  Segment,
  Grid,
  Header,
  Icon,
  Button,
  Image
} from 'semantic-ui-react';

import Dropzone from 'react-dropzone';

class ImageSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      imageSrc: '',
      loaded: false
    };
  }

  componentDidMount() {
    if (this.props.file) {
      this.previewFile(this.props.file);
    }
  }

  previewFile(f) {
    let reader = new FileReader();
    this.setState({ loaded: false });

    reader.onload = e => {
      this.setState({
        imageSrc: reader.result,
        loaded: true
      });
      this.props.changeFile(f);
    };

    reader.readAsDataURL(f);
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    // Do something with files
    console.log(acceptedFiles);
    if (acceptedFiles.length > 0) {
      this.previewFile(acceptedFiles[0]);
    }
  };

  render() {
    return (
      <Dropzone
        onDrop={this.onDrop}
        accept="image/*"
        multiple={false}
        disableClick
      >
        {({ getRootProps, getInputProps, isDragActive, open }) => {
          const { ref, ...rootProps } = getRootProps();
          return (
            <div>
              <Ref innerRef={ref}>
                <Segment
                  {...rootProps}
                  placeholder
                  inverted={isDragActive}
                  secondary={isDragActive}
                >
                  <Grid
                    columns={this.state.loaded ? 2 : 1}
                    stackable
                    textAlign="center"
                  >
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column>
                        <Header icon>
                          <Icon name="upload" />
                          <p>Drag an image file here</p>
                          <p>OR</p>
                        </Header>
                        <p>
                          <Button primary onClick={() => open()}>
                            Choose file
                          </Button>
                        </p>
                        <input {...getInputProps()} />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          src={this.state.imageSrc}
                          className={this.state.loaded ? 'loaded' : ''}
                          size="small"
                          centered
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Ref>
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

export default ImageSelect;
